import { api } from "../helpers";
import { MAX_ITEMS_PER_PAGE } from "../helpers/constants";
import { ITrackingAppointmentRes } from "../pages/admin/ShopDashboard/apt.type";
import { ITrackingCouponsResData } from "../pages/admin/ShopDashboard/coupon.type";
type IResponseBody<T> = {
  code: number, payload: T, message: string;
}

const getTrackingCoupon = (shop_id: string) => async (page: number, startTime: string, endTime: string): Promise<IResponseBody<ITrackingCouponsResData>> => {
  const params = { page, startTime, endTime, pageSize: MAX_ITEMS_PER_PAGE };
  return await api.get(`admin/tracking/coupons`, { headers: { 'x-api-key': shop_id }, params }).then((response: any) => {
    return response.data;
  });
};

const getTrackingAppointments = (shop_id: string) => async (page: number, startTime: string, endTime: string): Promise<IResponseBody<ITrackingAppointmentRes>> => {
  const params = { page, startTime, endTime, pageSize: MAX_ITEMS_PER_PAGE };
  return await api.get(`admin/tracking/appointments`, { headers: { 'x-api-key': shop_id }, params }).then((response: any) => {
    return response.data;
  });
};

export const trackingService = {
  getTrackingCoupon,
  getTrackingAppointments,
};