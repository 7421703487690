// export const formatPhone = (phone: string) => {
//   if (!phone) {
//     return '';
//   }
//   const pattern = /([\d]{3})([\d]{2})([\d]{4})/;
//   const group = phone.match(pattern);
//   if (!group) {
//     return phone;
//   } else {
//     return `(${group[1]}) ${group[2]}-${group[3]}`;
//   }
// };
export const formatPhone = (value: string) => {
  const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return value;
};

export const maskPhone = (val: string | number) => {
  if (!val) return '';
  const inputValue = String(val);
  return (
    '(***) ***-' + inputValue.slice(inputValue.length - 4, inputValue.length)
  );
};

const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

export const validatePhoneNumber: (rule: any, value: string) => Promise<void> = (_, value) => {
  return phoneRegex.test(value) ? Promise.resolve() : Promise.reject(new Error('Invalid phone number format'));
};