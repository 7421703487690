import React, { Fragment, useEffect, useState } from 'react'
import {
  MdClose, MdLock, MdRemoveRedEye, FaRegEdit,
  GoLock, MdModeEdit, GiPriceTag
} from 'react-icons/all'
import { DatePicker, Form, Input, Modal, Pagination, Switch, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { accountService } from '../../services/account.service';
import dayjs from 'dayjs';
import { formatPhoneNumber } from 'react-phone-number-input'
import UnControlledDropdownModal from '../../components/UnControlledDropdownModal';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import TextArea from 'antd/es/input/TextArea';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const AccountManage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

  const [formExtendSms] = Form.useForm();
  const [showExtendSmsModal, setShowExtendSmsModal] = useState(false);
  const [idExtendSms, setExtendIdSms] = useState(null);

  const [formPriceSms] = Form.useForm();
  const [showPriceSmsModal, setShowPriceSmsModal] = useState(false);
  const [objectPriceSmsModal, setObjectPriceSmsModal] = useState<any>(null);
  const [nameSelect, setNameSelect] = useState('');

  const [detailSalon, setDetailSalon] = useState<IDetailSalon | null>(null);
  const [shopsOfSalon, setShopsOfSalon] = useState<ISalon[]>([]);

  const getDataList = async (pageNumber: number, startTime: any | null, endTime: any, keyword: any) => {
    setLoading(true);
    try {
      const response = await accountService.filterList(pageNumber, MAX_ITEMS_PER_PAGE, startTime, endTime, keyword);
      if (response.code === 200) {
        setTotal(response.payload.total);
        setLstDanhSach(response.payload.accounts);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initData = async () => {
      setCurrentPage(1);
      getDataList(1, null, null, null);
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const values = await form.validateFields();
    getDataList(pageNumber, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
  };

  const handleSearch = async () => {
    const values = await form.validateFields();
    setCurrentPage(1);
    getDataList(1, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
  };

  const handleViewDetail = (id: any) => () => {
    navigate(`/account-summary/detail/${id}`);
  };

  const handleEdit = (id: any) => () => {
    navigate(`/account-summary/edit/${id}`);
  };

  const handleLockAccount = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Are you sure want to Lock this Account ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Lock it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await accountService.lockAccount(id);
        if (response.code === 200) {
          message.success('Lock successful');
          const values = await form.validateFields();
          getDataList(currentPage, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
        } else {
          message.error({ content: response.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      }
    }
  };

  const handleResetPass = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Are you sure want to Reset this Account ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Reset it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await accountService.resetPassAccount(id);
        if (response.code === 200) {
          message.success('Reset password successful');
        } else {
          message.error({ content: response.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      }
    }
  };

  const handleDelete = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Are you sure want to Delete this Account ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await accountService.deleteAccount(id);
        if (response.code === 200) {
          message.success('Delete successful');
          const values = await form.validateFields();
          getDataList(currentPage, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
        } else {
          message.error({ content: response.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      }
    }
  };

  const handleExtendSMS = (id: any) => () => {
    setShowExtendSmsModal(true);
    setExtendIdSms(id);
  };

  const handleSMS = (id: any, name: string) => async () => {
    const response = await accountService.getPriceSmsByAccount(id);
    if (response.code === 200) {
      setObjectPriceSmsModal(response.payload);
      setNameSelect(name);
    } else {
      message.error({ content: response.message, duration: 2 });
    }
  };

  useEffect(() => {
    if (objectPriceSmsModal) {
      formPriceSms.setFieldsValue({
        name: nameSelect,
        pricing: objectPriceSmsModal.pricing,
      });
      setShowPriceSmsModal(true);
    }
  }, [objectPriceSmsModal, formPriceSms, nameSelect]);

  const renderActionModal = (item: any) => {
    const props = [
      {
        icon: <MdRemoveRedEye className="menu-item menu-item-detail" />,
        label: 'View Detail',
        onClick: handleViewDetail(item.id),
      },
      {
        icon: <MdModeEdit className="menu-item color-main" />,
        label: 'Edit',
        onClick: handleEdit(item.id),
      },
      {
        icon: <MdLock className="menu-item" />,
        label: 'Lock Account',
        onClick: handleLockAccount(item.id),
      },
      {
        icon: <GoLock className="menu-item color-reset" />,
        label: 'Reset pass',
        onClick: handleResetPass(item.id),
      },
      {
        icon: <MdClose className="menu-item color-alert" />,
        label: 'Delete',
        onClick: handleDelete(item.id),
      },
      {
        icon: <FaRegEdit className="menu-item color-note" />,
        label: 'Extend sms',
        onClick: handleExtendSMS(item.id),
      },
      {
        icon: <GiPriceTag className="menu-item menu-item-extend" />,
        label: 'Price SMS',
        onClick: handleSMS(item.id, `${item.firstName} ${item.lastName}`),
      },
    ];
    return (
      <UnControlledDropdownModal items={props} />
    );
  };

  const handleCloseEditorModal = () => {
    setShowExtendSmsModal(false);
    setShowPriceSmsModal(false);
    formPriceSms.resetFields();
    formExtendSms.resetFields();
    setObjectPriceSmsModal(null);
    setExtendIdSms(null);
  }

  const onSubmitExtendSms = async (values: any) => {
    try {
      const objectUpdate = {
        balance: values.balance,
        charge: values.charge ? values.charge : false,
        note: values.note
      }
      const response = await accountService.extendSms(idExtendSms, objectUpdate);
      if (response.code === 200) {
        message.success('Extend SMS successful');
        setShowExtendSmsModal(false);
        formExtendSms.resetFields();
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  const onSubmitPriceSms = async (values: any) => {
    try {
      const objectUpdate = {
        accountId: objectPriceSmsModal.accountId,
        priceSms: values.pricing
      }
      const response = await accountService.updatePriceSms(objectUpdate);
      if (response.code === 200) {
        message.success('Change successful');
        setShowPriceSmsModal(false);
        formPriceSms.resetFields();
        setObjectPriceSmsModal(null);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  const handleClickDetail = async (item: IDetailSalon) => {
    setShopsOfSalon([]);
    try {
      const response = await accountService.getShopListById(item.id);
      if (response.code === 200) {

        if (response.payload?.length === 1) {
          navigate(`/account-summary/shop/${response.payload?.[0]?.uuid}`);
        } else {
          setShopsOfSalon(response.payload);
          setDetailSalon(item);
        }
      } else if (response.status === 404) {
        message.error('Id not found');
      } else {
        message.error(response.message);
      }
    } catch (error) { }
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <Modal
        title='EXTEND SMS'
        open={showExtendSmsModal}
        onCancel={handleCloseEditorModal}
        maskClosable={false}
        footer={[]}>
        {/* Hiển thị trình soạn thảo trong modal */}
        <Form form={formExtendSms} name="formExtendSms"
          labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
          onFinish={onSubmitExtendSms}>
          <Form.Item
            name="note"
            label="Note"
            rules={[{ required: true }]}>
            <TextArea
              rows={4} // Số dòng hiển thị ban đầu
            />
          </Form.Item>
          <Form.Item name="balance"
            label='Balance'
            rules={[{ required: true }]}>
            <Input type="number" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="charge"
            label="Charge">
            <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
          <div className="form-group text-right">
            <button type="button" className="btn btn-secondary" onClick={handleCloseEditorModal}>Cancel</button>
            <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
              Extend
            </></button>
          </div>
        </Form>
      </Modal>

      <Modal
        title='PRICE SMS'
        open={showPriceSmsModal}
        onCancel={handleCloseEditorModal}
        maskClosable={false}
        footer={[]}>
        {/* Hiển thị trình soạn thảo trong modal */}
        <Form form={formPriceSms} name="formPriceSms"
          labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
          onFinish={onSubmitPriceSms}>
          <Form.Item
            name="name"
            label="Owner Name">
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item name="pricing"
            label='Price'
            rules={[{ required: true }]}>
            <Input autoComplete="off" type="number" />
          </Form.Item>

          <div className="form-group text-right">
            <button type="button" className="btn btn-secondary" onClick={handleCloseEditorModal}>Cancel</button>
            <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
              Change
            </></button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={`${detailSalon?.firstName || '--'} ${detailSalon?.lastName || '--'}`}
        open={!!detailSalon}
        onCancel={() => setDetailSalon(null)}
        maskClosable={false}
        width={'800px'}
        footer={[]}>
        <div className="row" style={{ marginTop: 30 }}>
          {shopsOfSalon.map(o => (
            <Link type="button" className="col-6" key={o.id} to={`/account-summary/shop/${o.uuid}`}>
              <div className="card shadow">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div className="card-title" style={{ fontWeight: '600', fontSize: '1.25rem' }} >{o.companyName}</div>
                      <div className="card-subtitle">{o.address}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

      </Modal>
      <Form form={form} name="search">
        <div className="row">
          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item name="startTime">
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date To'
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item name="endTime">
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date From'
                />
              </Form.Item>
            </div>
          </div>

          <div className='col' style={{ flex: 1 }}>
            <div className="form-group">
              <Form.Item name="keyword">
                <Input className='form-control' placeholder="Name, Phone or Email" autoComplete="off" />
              </Form.Item>
            </div>
          </div>

          <div style={{ paddingRight: '0.75rem' }}>
            <div className="d-flex align-items-center">
              <div className="form-group">
                <button type="button" onClick={handleSearch} className="btn-container-create thin btn">
                  <i className="fas fa-fw fa-search"></i>
                  Search
                </button>
                <Link type="button" className="btn-container-create thin btn ml-2" to={'/account-summary/create'}>
                  <i className="fas fa-fw fa-plus"></i>
                  Add Account
                </Link>
              </div>
            </div>
          </div>

        </div>
      </Form>

      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-body">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th className='text-center' >Order</th>
                    <th>Email</th>
                    <th>Owner name</th>
                    <th>Owner phone</th>
                    <th>Status</th>
                    <th>Register date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={7}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {lstDanhSach?.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                          <td className='text-center'>{index + 1 + (currentPage - 1) * 10}</td>
                          <td>
                            <button className="btn btn-link" onClick={() => handleClickDetail(item)}>{item.email}</button>
                          </td>
                          <td>{item.firstName} {item.lastName}</td>
                          <td>{formatPhoneNumber(item.phone)}</td>
                          <td>{item.status === 0 ? 'Lock' : 'Active'}</td>
                          <td>
                            {dayjs(item.registerDate).format('MMM DD YYYY HH:mm')}
                          </td>
                          <td className="text-center">
                            {renderActionModal(item)}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-right'>
            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={MAX_ITEMS_PER_PAGE}
              onChange={onPageChanged}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


type IDetailSalon = {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  state: string;
  city: string;
  password: string;
  zipCode: string;
  confirmCode: string;
  registerDate: number;
  numberLoginFail: number;
  role: number;
  status: number;
  createDate: number;
  createByAccountId: number;
  userPresentId: number;
  balanceAmount: number;
  countryName: string;
  accessToken: string;
  accountType: number;
  isSendWarningSms1: number;
  isSendWarningSms2: number;
};


export interface ISalon {
  id: number;
  accountId: number;
  uuid: string;
  address: string;
  city: string;
  createDate: number;
  email: string;
  phone: string;
  companyName: string;
  state: string;
  status: number;
  zip: string;
  countryId: number;
  timeZone: string;
  createByAccountId: number;
  apiKey: string;
  isLinkBooking: number;
}