import { api } from '../helpers';

const filterList = async (page: number | null, pageSize: number | null, startTime: any | null, endTime: any, keyword: any) => {
    const params = { pageSize, page, startTime, endTime, keyword };
    return await api.get('/agent/getAll', { params }).then((response: any) => {
        return response.data;
    });
};

const createAgent = async (payload: any) => {
    const data = {
        type: 'ICHECKINS/AGENT_MANAGMENT/CREATE_AGENT_MANAGMENT',
        payload
    };
    return await api.post(`/agent/create`, data).then((response) => {
        return response.data;
    });
};

const deleteAgent = async (id: any) => {
    return await api.delete(`agent/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const updatePriceSms = async (payload: any) => {
    const data = {
        type: "ICHECKINS/AGENT_MANAGMENT/UPDATE_PRICE_AGENT_MANAGMENT",
        payload
    };
    return await api.put(`/admin/updatePriceSms`, data).then((response: any) => {
        return response.data;
    });
};

const getDetailById = async (id: any) => {
    return await api.get(`/agent/detail/${id}`).then((response: any) => {
        return response.data;
    });
};

const updateAgent = async (payload: any) => {
    const data = {
        type: 'ICHECKINS/AGENT_MANAGMENT/UPDATE_AGENT_MANAGMENT',
        payload
    };
    return await api.put(`agent/update`, data).then((response) => {
        return response.data;
    });
};


export const agentService = {
    filterList,
    createAgent,
    deleteAgent,
    updatePriceSms,
    getDetailById,
    updateAgent
};