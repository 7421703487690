import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ICustomerItem, IDetailSalonInfo } from './types';
import { DatePicker, Form, Input, Pagination, Select, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { customerService } from '../../../services/customer.service';
import { MAX_ITEMS_PER_PAGE } from '../../../helpers/constants';
import { formatPhone } from '../../../helpers/formatPhone';

type Props = {
  detail: IDetailSalonInfo | null;
};
const CustomerTab = ({ detail }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [data, setData] = useState<ICustomerItem[]>([]);
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChanged = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const values = await form.validateFields();
    getDataList(pageNumber, values.startTime?.format('YYYY-MM-DD'), values.endTime?.format('YYYY-MM-DD'), values.keyword?.trim(), values.sortBy);
  };

  const getDataList = async (pageNumber: number, startTime: any | null, endTime: any, keyword: any, sortBy: string) => {
    if (!detail) return;
    setLoading(true);
    try {
      const response = await customerService.getListByShopId(detail?.apiKey?.toString())(pageNumber, MAX_ITEMS_PER_PAGE, startTime, endTime, keyword, sortBy);
      if (response.code === 200) {
        setTotal(response.payload.total);
        setData(response.payload.data);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initData = async () => {
      setCurrentPage(1);
      getDataList(1, null, null, null, '');
    };
    initData();
  }, []);


  const handleFinish = (values: any) => {
    getDataList(1, values.startTime?.format('YYYY-MM-DD'), values.endTime?.format('YYYY-MM-DD'), values.keyword?.trim(), values.sortBy);
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <Form form={form} initialValues={{ startDate, endDate }} onFinish={handleFinish}>
        <div className="row">
          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'startDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date To'
                  onChange={setStartDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'endDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date From'
                  minDate={startDate || undefined}
                  onChange={setEndDate}
                />
              </Form.Item>
            </div>
          </div>
          <div className='col' style={{ flex: 1 }}>
            <div className="form-group">
              <Form.Item name="keyword">
                <Input className='form-control' placeholder="Name, Phone or Email" autoComplete="off" />
              </Form.Item>
            </div>
          </div>
          <div className='col' style={{ flex: 1 }}>
            <Form.Item name="sortBy">
              <Select placeholder='Sort By' size="large" allowClear>
                <Select.Option key={'AMOUNT'} value={'AMOUNT'}>{'Amount'}</Select.Option>
                <Select.Option key={'POINT'} value={'POINT'}>{'Point'}</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div style={{ paddingRight: '0.75rem' }}>
            <div className="form-group">
              <button type="submit" className="btn btn-info">search</button>
            </div>
          </div>
        </div>
      </Form>
      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-body">

              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th className='text-center' >Name</th>
                    <th>Phone</th>
                    <th className='text-right'>Birthday</th>
                    <th className='text-right'>Point</th>
                    <th className='text-right'>Balance</th>
                    <th className='text-right'>Num visit</th>
                    <th className='text-right'>Last date visit</th>
                    <th className='text-right'>action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={7}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> loading...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {data?.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                          <th className='text-center text-lg text-black' >{item.name}</th>
                          <td className='text-lg text-black'>{formatPhone(item.phone)}</td>
                          <td className='text-lg text-right birthday'>{item.birthday ? dayjs(item.birthday).format('MM-DD-YYYY') : '--'}</td>
                          <td className='text-lg text-right point'>{item.point || '0'}</td>
                          <td className='text-lg text-right balance'>{item.balance}</td>
                          <td className='text-lg text-right numVisit'>{item.numVisit || 0}</td>
                          <td className='text-lg text-right lastDateVisit'>{item.lastDateVisit ? dayjs(item.lastDateVisit).format('MM-DD-YYYY') : '--'}</td>
                          <td className='text-lg text-right'>
                            <button disabled className="btn btn-info">Detail</button>
                            {/* <Link type="button" className="btn btn-secondary" 
                              to={`/account-summary/shop/${detail?.apiKey}/customer/${item.id}`}
                              state={{ path: `/account-summary/shop/${detail?.uuid}?tab=customer` }}
                            >detail</Link> */}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-right'>
            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={MAX_ITEMS_PER_PAGE}
              onChange={onPageChanged}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerTab;
