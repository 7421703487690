import { api } from "../helpers";
import { ICustomerItem } from "../pages/admin/ShopDashboard/types";

type IResponseBody = {
  code: number, payload: { total: number, data: ICustomerItem[] }, message: string;
}

const getListByShopId
  = (shop_id: string) => async (page: number | null, pageSize: number | null, startTime: any | null, endTime: any, keyword: any, sortBy?: string): Promise<IResponseBody> => {
    const params = { pageSize, page, startTime, endTime, keyword };
    return await api.get(`customer/getListByShopId`, { params, headers: { 'x-api-key': shop_id } }).then((response: any) => {
      return response.data;
    });
  };

const getCustomerDetailById = (shop_id: string) => async (customerId: string) => {
  return await api.get(`customer/getDetailCustomer/${customerId}`, { headers: { 'x-api-key': shop_id } }).then((response: any) => {
    return response.data;
  });
};

export const customerService = {
  getListByShopId,
  getCustomerDetailById,
};