import React from 'react';
import PropTypes from 'prop-types';
import {
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';

function UnControlledDropdownModal({ items }: { items: any[] }) {
    return (
        <UncontrolledDropdown>
            <DropdownToggle className="btn btn-light btn-icon-split">
                <img style={{ width: '30px' }} src='/images/icon-dots.png' alt="icon" />
            </DropdownToggle>
            <DropdownMenu className="home-dropdown menu-dots bg-white">
                {items?.map((item: any, index: number) => (
                    <DropdownItem onClick={item.onClick ? item.onClick : null} key={index}>
                        {item.icon}
                        {item.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

UnControlledDropdownModal.propTypes = {
    items: PropTypes.array.isRequired,
};

export default UnControlledDropdownModal;
