import './styles/sb-admin-2.css';
import './assets/font-awesome/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AccountRoute } from './components/AccountRoute';
import { PrivateRoute } from './components/PrivateRoute';
import { AccountDetail } from './pages/admin/AccountManage.Detail';
import { AccountManage } from './pages/admin/AccountManage.List';
import { AccountEdit } from './pages/admin/AccountManage.Edit';
import { AccountOwnerEdit } from './pages/admin/AccountManage.Owner.Edit';
import { AccountOwnerCreate } from './pages/admin/AccountManage.Owner.Create';
import { AccountCreate } from './pages/admin/AccountManage.Create';
import { AgentManageList } from './pages/admin/AgentManage.List';
import { AgentCreate } from './pages/admin/AgentManage.Create';
import { SmsManageList } from './pages/admin/SmsManage.List';
import { SmsManageDetail } from './pages/admin/SmsManage.Detail';
import { AgentEdit } from './pages/admin/AgentManage.Edit';
import { SettingCouponList } from './pages/admin/SettingCoupon.List';
import { SettingCouponDetail } from './pages/admin/SettingCoupon.Detail';
import { SettingCouponEdit } from './pages/admin/SettingCoupon.Edit';
import { SettingCouponCreate } from './pages/admin/SettingCoupon.Create';
import SalonDashboard from './pages/admin/SalonDashboard';
import ShopDashboard from './pages/admin/ShopDashboard';
import CustomerDetail from './pages/admin/Customer.Detail';

function App() {
  return (
    <div className='App' id='wrapper'>
      <Router>
        <Routes>
          <Route path='/' element={<AccountRoute />} />
          <Route path='/login' element={<AccountRoute />} />
          <Route path='/dashboard' element={<PrivateRoute> <SalonDashboard /> </PrivateRoute>} />
          <Route path='/account-summary' element={<PrivateRoute> <AccountManage /> </PrivateRoute>} />
          <Route path='/account-summary/detail/:id' element={<PrivateRoute> <AccountDetail /> </PrivateRoute>} />
          <Route path='/account-summary/edit/:id' element={<PrivateRoute> <AccountEdit /> </PrivateRoute>} />
          <Route path='/account-summary/shop/edit/:id' element={<PrivateRoute> <AccountOwnerEdit /> </PrivateRoute>} />
          <Route path='/account-summary/shop/create/:id' element={<PrivateRoute> <AccountOwnerCreate /> </PrivateRoute>} />
          <Route path='/account-summary/shop/:id' element={<PrivateRoute> <ShopDashboard /> </PrivateRoute>} />
          <Route path='/account-summary/shop/:shop_api_key/customer/:customer_id' element={<PrivateRoute> <CustomerDetail /> </PrivateRoute>} />
          <Route path='/account-summary/create' element={<PrivateRoute> <AccountCreate /> </PrivateRoute>} />
          <Route path='/agent-managment' element={<PrivateRoute> <AgentManageList /> </PrivateRoute>} />
          <Route path='/agent-managment/create' element={<PrivateRoute> <AgentCreate /> </PrivateRoute>} />
          <Route path='/agent-managment/edit/:id' element={<PrivateRoute> <AgentEdit /> </PrivateRoute>} />
          <Route path='/sms-managment' element={<PrivateRoute> <SmsManageList /> </PrivateRoute>} />
          <Route path='/sms-managment/detail/:id' element={<PrivateRoute> <SmsManageDetail /> </PrivateRoute>} />
          <Route path='/setting-coupon' element={<PrivateRoute> <SettingCouponList /> </PrivateRoute>} />
          <Route path='/setting-coupon/create' element={<PrivateRoute> <SettingCouponCreate /> </PrivateRoute>} />
          <Route path='/setting-coupon/detail/:id' element={<PrivateRoute> <SettingCouponDetail /> </PrivateRoute>} />
          <Route path='/setting-coupon/edit/:id' element={<PrivateRoute> <SettingCouponEdit /> </PrivateRoute>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
