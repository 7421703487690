import React, { Form, Input, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import InputFormatPhone from 'react-phone-number-input/input';
import { useNavigate, useParams } from 'react-router-dom';
import timeZoneData from '../../../mockdata/_timeZone.json';
import { accountService } from '../../../services';
import { IDetailSalonInfo } from './types';

type Props = {
  detail: IDetailSalonInfo | null;
  fetchDetail: () => Promise<void>;
};
const SalonInfoTab = ({ detail, fetchDetail }: Props) => {
  const navigate = useNavigate();
  const onClose = () => navigate(`/account-summary`);

  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inputPhone, setPhone] = useState<any>(null);

  useEffect(() => {
    if (!detail) return;
    formEdit.setFieldsValue({
      companyName: detail.companyName,
      city: detail.city,
      email: detail.email,
      timeZone: detail.timeZone,
      address: detail.address,
      phone: detail.phone,
      state: detail.state,
      zip: detail.zip
    });
    setPhone(detail.phone);
  }, [detail]);

  const onSubmit = async (values: any) => {
    if (!detail) return;
    setLoading(true);
    try {
      const objectUpdate = {
        accountId: detail.accountId,
        address: values.address,
        apiKey: detail.apiKey,
        city: values.city,
        companyName: values.companyName,
        countryId: detail.countryId,
        createByAccountId: detail.createByAccountId,
        createDate: detail.createDate,
        email: values.email,
        id: detail.id,
        isLinkBooking: detail.isLinkBooking,
        isRandomTicketNumber: detail.isRandomTicketNumber,
        linkShareFormCovid19: detail.linkShareFormCovid19,
        phone: inputPhone,
        shopId: detail.id,
        state: values.state,
        status: detail.status,
        timeZone: values.timeZone,
        urlImageReviews: detail.urlImageReviews,
        urlImageSignIn: detail.urlImageSignIn,
        uuid: detail.uuid,
        zip: values.zip,
      }

      const response = await accountService.updateShopById(objectUpdate);
      if (response.code === 200) {
        message.success('Update successful');
        fetchDetail();
      } else {
        setLoading(false);
        message.error({ content: response.message, duration: 2 });
      }

    } catch (error: any) {
      setLoading(false);
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  if (loading) return (
    <>
      <span className='spinner-border spinner-border-sm mr-1'></span> Loading...
    </>
  )

  return (
    <div className="row">
      <div className="col-12">
        <div className="card shadow mb-4">
          <Form form={formEdit}
            name="detail" onFinish={onSubmit}
            labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <div className="card-body" style={{ marginTop: '60px' }}>
              <div className="row">
                <div className='col-sm-12'>
                  <div className="row">
                    <div className='col-sm-6'>
                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="companyName"
                            label="Shop name"
                            rules={[{ required: true }]}>
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="city"
                            label="City"
                            rules={[{ required: true }]}>
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "The input is not valid E-mail !",
                              }
                            ]}>
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="timeZone"
                            label="Time Zone">
                            <Select placeholder="Time Zone" size="large">
                              <Select.Option key={''} value={''}>{''}</Select.Option>
                              {timeZoneData.map((item: any, index) => (
                                <Select.Option key={index} value={item.value}>{item.text}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="address"
                            label="Address"
                            rules={[{ required: true }]}>
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[{ required: true }]}>
                            <InputFormatPhone
                              className="form-phone-validate form-control"
                              country="US"
                              autoComplete="off"
                              name="phone"
                              onChange={e => setPhone(e)}
                              placeholder="(___)___-___" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="state"
                            label="State">
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12">
                          <Form.Item
                            name="zip"
                            label="Zip Code">
                            <Input size='large' autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 text-right'>
                  <div className="form-group">
                    <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
                      {loading && (
                        <span className='spinner-border spinner-border-sm mr-1'></span>
                      )}
                      Save
                    </></button>
                  </div>
                </div>
              </div>
            </div>


          </Form>
        </div>
      </div>
    </div >
  )
}

export default SalonInfoTab;
