import { DatePicker, Form, Pagination, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { formatCurrency, formatNumber } from '../../../helpers/formatCurrency';
import { formatPhone } from '../../../helpers/formatPhone';
import { trackingService } from '../../../services/tracking.service';
import { ITrackingCouponsResData } from './coupon.type';
import { IDetailSalonInfo } from './types';
import { MAX_ITEMS_PER_PAGE } from '../../../helpers/constants';
import { CashIcon, IncomesIcon, OtherIcon, RedeemIcon, TransactionIcon } from './DashboardIcons';
type Props = {
  detail: IDetailSalonInfo | null
};

const DATE_FORMAT = 'MM-DD-YYYY';

type IFormValues = { startDate: Dayjs, endDate: Dayjs };

const CheckInCouponTab = ({ detail }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('month'));
  const [data, setData] = useState<ITrackingCouponsResData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const summaryDashboardItems = [
    {
      key: 'coupon_profit', label: 'Coupon Profit',
      fieldName: 'summary.marketingProfit', value: formatCurrency(data?.summary.marketingProfit),
      icon: <IncomesIcon />, background: 'rgb(17, 194, 109)',
    },
    {
      key: 'total_coupon_send', label: 'Total Coupon Sent',
      fieldName: 'summary.totalCouponSend', value: formatNumber(data?.summary.totalCouponSend),
      icon: <TransactionIcon />, background: 'rgb(255, 128, 64)',
    },
    {
      key: 'total_coupon_used', label: 'Total Coupon Used',
      fieldName: 'summary.totalUsed', value: formatNumber(data?.summary.totalUsed),
      icon: <OtherIcon />, background: 'rgb(62, 142, 247)',
    },
    {
      key: 'total_bill', label: 'Total Bill',
      fieldName: 'summary.totalBill', value: formatCurrency(data?.summary.totalBill),
      icon: <RedeemIcon />, background: 'rgb(89, 101, 238)',
    },
    {
      key: 'total_discount', label: 'Total Discount',
      fieldName: 'summary.totalDiscount', value: formatCurrency(data?.summary.totalDiscount),
      icon: <CashIcon />, background: 'rgb(255, 205, 23)',
    },
  ];

  const getDataList = async (pageNumber: number, startTime: Dayjs, endTime: Dayjs) => {
    setLoading(true);
    try {
      const response = await trackingService.getTrackingCoupon(detail?.apiKey || '')(pageNumber, startTime.format(DATE_FORMAT), endTime.format(DATE_FORMAT));
      if (response.code === 200) {
        setData(response.payload);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    getDataList(1, startDate, endDate);
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const values = await form.validateFields();
    getDataList(pageNumber, values.startDate, values.endDate);
  };


  const handleFinish = ({ startDate, endDate }: IFormValues) => getDataList(1, startDate, endDate);

  return (
    <div className="container-fluid coupon-check-in" style={{ paddingTop: '10px' }}>
      <Form form={form} initialValues={{ startDate, endDate }} onFinish={handleFinish}>
        <div className="row w-100 ml-0">
          <div style={{ flex: 1, paddingLeft: '0.75rem' }}>
            <h4>Coupon Check In</h4>
          </div>
          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'startDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date To'
                  onChange={setStartDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'endDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date From'
                  minDate={startDate}
                  onChange={setEndDate}
                />
              </Form.Item>
            </div>
          </div>
          <div >
            <div className="form-group text-right">
              <button type="submit" className="btn btn-info">search</button>
            </div>
          </div>
        </div>
      </Form>
      <div className="dashboard-summary" style={{ gap: '8px' }}>
        {summaryDashboardItems.map(o => (
          <div key={o.key}>
            <div className="card shadow mb-4 card-dashboard" style={{ backgroundColor: o.background }}>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className='text-white'>
                    <div className={`card-title ${o.fieldName}`} >{o.value}</div>
                    <div className="card-subtitle">{o.label}</div>
                  </div>
                  {o.icon}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-body" >

              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Date & Time</th>
                    <th>Ticket</th>
                    <th>Total Bill</th>
                    <th>Discount</th>
                    <th>Customer</th>
                    <th>Coupon name</th>
                    <th>Coupon code</th>
                    <th>Source</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={8}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> loading...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {data?.items.content.map((o) => (
                        <tr key={o.id}>
                          <th className='field.createDate text-black'>{dayjs(o.createDate).format('MM-DD-YYYY hh:mm A')}</th>
                          <th className='field.billId text-black'>#{o.billId}</th>
                          <th className='field.profitValue text-black' >{formatCurrency(o.profitValue)}</th>
                          <th className='field.discountValue field.discountType text-black'><span style={{ background: 'red', color: '#fff', padding: '2px 4px', borderRadius: '2px' }}>{(() => {
                            let discountValue = o.discountValue;
                            if (o.discountType === "PERCENT") {
                              return -o.discountValue + '%';
                            }
                            return formatCurrency(-discountValue);
                          })()}</span></th>
                          <th>
                            <div className='field.customerName text-black'>{o.customerName || 'Unknown'}</div>
                            <div className='field.customerPhone text-black' style={{ opacity: 0.5 }}>{formatPhone(o.customerPhone)}</div>
                          </th>
                          <th className='field.couponName text-black'>{o.couponName}</th>
                          <th className='field.couponCode text-black'>{o.couponCode}</th>
                          <th className='field.sourceType text-black'>{o.sourceType || '--'}</th>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-right'>
            <Pagination
              current={currentPage}
              total={data?.items.totalElements || 0}
              pageSize={MAX_ITEMS_PER_PAGE}
              onChange={onPageChanged}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckInCouponTab;
