import message from 'antd/es/message';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { accountService } from '../../services/account.service';
import { Col, Row } from 'reactstrap';
import LabelDescription from '../../components/LabelDescription';
import { formatPhoneNumber } from 'react-phone-number-input';
import { MdClose, MdModeEdit } from 'react-icons/md';
import UnControlledDropdownModal from '../../components/UnControlledDropdownModal';
import { KEY_ID_ACCOUNT_SELECED } from '../../helpers/constants';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export const AccountDetail = () => {
  const onClose = () => {
    navigate('/account-summary');
  };
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [accountDetail, setChiTiet] = useState<any>(null);
  const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await accountService.getDetailById(id).then((response) => {
          if (response.code === 200) {
            setChiTiet(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });

        await accountService.getShopListById(id).then((response) => {
          if (response.code === 200) {
            setLstDanhSach(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    if (!accountDetail) {
      fetchCongDoanData();
    }
  });

  const handleEdit = (uuid: any) => () => {
    localStorage.setItem(KEY_ID_ACCOUNT_SELECED, id as string);
    navigate(`/account-summary/shop/edit/${uuid}`);
  }

  const handleDelete = (uuid: any) => async () => {
    const result = await Swal.fire({
      title: 'Are you sure want to delete this Shop ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await accountService.deleteShop(uuid);
        if (response.code === 200) {
          message.success('Delete successful')
          const rowToDelete = document.getElementById(uuid);
          if (rowToDelete) {
            rowToDelete.remove();
          }
        } else {
          setLoading(false);
          message.error({ content: response.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      }
    }
  }

  const renderActionModal = (item: any) => {
    const props = [
      {
        icon: <MdModeEdit className="menu-item color-main" />,
        label: 'Edit',
        onClick: handleEdit(item.uuid),
      },
      {
        icon: <MdClose className="menu-item color-alert" />,
        label: 'Delete',
        onClick: handleDelete(item.uuid),
      },
    ];
    return (
      <UnControlledDropdownModal items={props} />
    );
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between mb-4">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          <i className="fas fa-fw fa-angle-left"></i>
          Back
        </button>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card-header py-3 card-header-customer">
            <div className="group-title"><h4>Company Information</h4><span>Information account</span></div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body" style={{ marginTop: '60px' }}>

              {loading ? (
                // Hiển thị bộ Loading khi loadingLoadList là true
                <div className='text-center'>
                  <span className='spinner-border spinner-border-sm mr-1'></span> Loading...
                </div>
              ) : (
                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                <Row className="box-content-detail">
                  <Col md={6}>
                    <LabelDescription label="Company Phone" description={formatPhoneNumber(accountDetail?.phone)} />
                  </Col>
                  <Col md={6}>
                    <LabelDescription label="Address" description={accountDetail?.address} />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card-header py-3 card-header-customer">
            <div className="group-title"><h4>Owner Information</h4><span>Information shop</span></div>
            <div className="group-title">
              <Link type="button" className="btn-container-create btn" to={`/account-summary/shop/create/${id}`}>
                <i className="fas fa-fw fa-plus"></i>
                Add Shop
              </Link>
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body" style={{ marginTop: '60px' }}>
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th className="w-25">Company Name</th>
                    <th className="w-20">Email</th>
                    <th className="w-25">Phone</th>
                    <th className="w-25">Address</th>
                    <th className="w-20">Online Booking Link</th>
                    <th className="w-5 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={6}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> Loading...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {lstDanhSach?.map((owner: any) => (
                        <tr key={owner.id} id={owner.uuid}>
                          <td>{owner.companyName}</td>
                          <td>{owner.email}</td>
                          <td>{formatPhoneNumber(owner.phone)}</td>
                          <td>{owner.address}</td>
                          <td>{owner.linkBookingOnline}</td>
                          <td className="text-center">
                            {renderActionModal(owner)}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

