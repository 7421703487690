import React, { Fragment, useEffect, useState } from 'react'
import { smsService } from '../../services';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { DatePicker, Form, Pagination, message } from 'antd';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const SmsManageDetail = () => {
    const onClose = () => {
        navigate('/sms-managment');
    };
    const navigate = useNavigate();

    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

    const getDataList = async (id: any, pageNumber: number, startTime: any, endTime: any) => {
        setLoading(true);
        try {
            const response = await smsService.filterDetailList(id, pageNumber, MAX_ITEMS_PER_PAGE, startTime, endTime);
            if (response.code === 200) {
                setTotal(response.payload.total);
                setLstDanhSach(response.payload.data);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initData = async () => {
            setCurrentPage(1);
            getDataList(id, 1, null, null);
        };
        if (id) {
            initData();
        }
    }, [id]);

    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
        const values = await form.validateFields();
        getDataList(id, pageNumber, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'));
    };

    const handleSearch = async () => {
        const values = await form.validateFields();
        setCurrentPage(1);
        getDataList(id, 1, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'));
    };

    return (
        <div className="container-fluid" style={{ paddingTop: '10px' }}>
            <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between mb-4">
                <button type="button" className="btn btn-secondary" onClick={onClose}>
                    <i className="fas fa-fw fa-angle-left"></i>
                    Back
                </button>
            </div>

            <Form form={form} name="search">
                <div className="row">
                    <div className='col-sm-3'>
                        <div className="form-group">
                            <Form.Item name="startTime">
                                <DatePicker
                                    size="large"
                                    format="DD/MM/YYYY"
                                    showTime={false}
                                    inputReadOnly={true}
                                    placeholder='Send Date To'
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className="form-group">
                            <Form.Item name="endTime">
                                <DatePicker
                                    size="large"
                                    format="DD/MM/YYYY"
                                    showTime={false}
                                    inputReadOnly={true}
                                    placeholder='Send Date From'
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="row">
                <div className="col-12">
                    <div className="card-header py-3 card-header-customer">
                        <div className="group-title"><h4>SMS Detail</h4><span>Information sms</span></div>
                        <div className="group-title">
                            <button type="button" onClick={handleSearch} className="btn-container-create btn ml-2">
                                <i className="fas fa-fw fa-search"></i>
                                Search
                            </button>
                        </div>
                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-body" style={{ marginTop: '60px' }}>
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th className="w-20">Phone</th>
                                        <th className="w-20">Customer Name</th>
                                        <th className="w-25">SMS Content</th>
                                        <th className="w-5 text-center">SMS Count</th>
                                        <th className="w-15 text-center">Sent Time</th>
                                        <th className="w-15 text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{formatPhoneNumber(item.smsTo)}</td>
                                                    <td>{item.customerName}</td>
                                                    <td>{item.smsContent}</td>
                                                    <td>{item.smsCount}</td>
                                                    <td>
                                                        {dayjs(item.createDate).format('MMM DD YYYY HH:mm')}
                                                    </td>
                                                    <td>{item.status === 1 ? 'Success' : item.status === 4 ? 'Error' : ''}</td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Pagination
                            current={currentPage}
                            total={totalItems}
                            pageSize={MAX_ITEMS_PER_PAGE}
                            onChange={onPageChanged}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
