import { DatePicker, Form, Pagination, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { MAX_ITEMS_PER_PAGE } from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/formatCurrency';
import { formatPhone } from '../../../helpers/formatPhone';
import { trackingService } from '../../../services/tracking.service';
import { CashIcon, IconCalling, IconOnline, IncomesIcon, OtherIcon, TransactionIcon } from './DashboardIcons';
import { ITrackingAppointmentRes } from './apt.type';
import { IDetailSalonInfo } from './types';
const APPOINTMENT_SOURCE_TYPE = {
  WEBSITE: 1,
  CALl_IN: 0,
  AUTO_REMIND: 2,
};
const APPOINTMENT_SOURCE_TYPE_LABEL = {
  1: 'WEBSITE',
  0: 'CALl-IN',
  2: 'AUTO-REMIND',
};

type Props = {
  detail: IDetailSalonInfo | null
};

const DATE_FORMAT = 'MM-DD-YYYY';
const time = (date: string) => dayjs(date, 'MM/DD/YYYY HH:mm A');
type IFormValues = { startDate: Dayjs, endDate: Dayjs };

const AppointmentsTab = ({ detail }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('month'));
  const [data, setData] = useState<ITrackingAppointmentRes | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const summaryDashboardItems = [
    {
      key: 'totalAppointment', label: 'Total Appointment',
      fieldName: 'summary.totalAppointment', value: formatNumber(data?.summary.totalAppointment),
      icon: <TransactionIcon />, background: 'rgb(17, 194, 109)',
    },
    {
      key: 'totalBookAutoReminder', label: 'Total Book Auto Reminder',
      fieldName: 'summary.totalBookAutoReminder', value: formatNumber(data?.summary.totalBookAutoReminder),
      icon: <OtherIcon />, background: 'rgb(0, 193, 193)',
    },
    {
      key: 'totalBookOnline', label: 'Total Book Online',
      fieldName: 'summary.totalBookOnline', value: formatNumber(data?.summary.totalBookOnline),
      icon: <IncomesIcon />, background: 'rgb(255, 76, 82)',
    },
    {
      key: 'totalBookStore', label: 'Total Book Store',
      fieldName: 'summary.totalBookStore', value: formatNumber(data?.summary.totalBookStore),
      icon: <CashIcon />, background: '#6e707e',
      color: '#000',
    },
  ];

  const getDataList = async (pageNumber: number, startTime: Dayjs, endTime: Dayjs) => {
    setLoading(true);
    try {
      const response = await trackingService.getTrackingAppointments(detail?.apiKey || '')(pageNumber, startTime.format(DATE_FORMAT), endTime.format(DATE_FORMAT));
      if (response.code === 200) {
        setData(response.payload);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    getDataList(1, startDate, endDate);
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const values = await form.validateFields();
    getDataList(pageNumber, values.startDate, values.endDate);
  };


  const handleFinish = ({ startDate, endDate }: IFormValues) => getDataList(1, startDate, endDate);

  return (
    <div className="container-fluid coupon-check-in" style={{ paddingTop: '10px' }}>
      <Form form={form} initialValues={{ startDate, endDate }} onFinish={handleFinish}>
        <div className="row w-100 ml-0">
          <div style={{ flex: 1, paddingLeft: '0.75rem' }}>
            <h4>Appointments</h4>
          </div>
          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'startDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date To'
                  onChange={setStartDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'endDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date From'
                  minDate={startDate}
                  onChange={setEndDate}
                />
              </Form.Item>
            </div>
          </div>
          <div >
            <div className="form-group text-right">
              <button type="submit" className="btn btn-info">search</button>
            </div>
          </div>
        </div>
      </Form>
      <div className="dashboard-summary" style={{ gap: '8px', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
        {summaryDashboardItems.map(o => (
          <div key={o.key}>
            <div className="card shadow mb-4 card-dashboard" style={{ backgroundColor: o.background }}>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className='text-white'>
                    <div className={`card-title ${o.fieldName}`} >{o.value}</div>
                    <div className="card-subtitle">{o.label}</div>
                  </div>
                  {o.icon}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-body" >

              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Staff</th>
                    <th>Customer</th>
                    <th>Customer Phone</th>
                    <th>Services</th>
                    <th>TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={7}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> loading...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {data?.data?.map(o => (
                        <tr key={o.id}>
                          <th className='field.start_time text-black'>{time(o.start_time).format('ddd, MMM DD, YYYY')}</th>
                          <th className='field.start_time field.end_time text-black'>{time(o.start_time).format('HH:mm A')} - {time(o.end_time).format('HH:mm A')}</th>
                          <th className='field.staffName text-black'>{o.staffName}</th>
                          <th className='field.customerName text-black'>{o.customerName || 'Unknown'}</th>
                          <th className='field.customerPhone text-black'>{formatPhone(o.customerPhone)}</th>
                          <th className='field.services text-black' style={{ maxWidth: 200 }}>
                            <p>{o.services.map(o => `${o.serviceName} (${o.duration}m)`).join(', ')}</p>
                          </th>
                          <th>
                            <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                              {o.bookingType !== APPOINTMENT_SOURCE_TYPE.CALl_IN ? <IconOnline /> : <IconCalling />}
                              {/* @ts-ignore */}
                              {APPOINTMENT_SOURCE_TYPE_LABEL?.[o.bookingType] || '--'}
                            </div>
                          </th>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-right'>
            <Pagination
              current={currentPage}
              total={data?.total || 0}
              pageSize={MAX_ITEMS_PER_PAGE}
              onChange={onPageChanged}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppointmentsTab;
