import { DatePicker, Form, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { formatCurrency } from '../../helpers/formatCurrency';
import { ISummaryDashboard, salonServices } from '../../services/salon.service';

const DATE_FORMAT = 'MM-DD-YYYY';

type IFormValues = { startDate: Dayjs, endDate: Dayjs };

const SalonDashboard = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('month'));
  const [summaryDashboard, setSummaryDashboard] = useState<ISummaryDashboard | null>(null);

  const getDataList = async (startTime: Dayjs, endTime: Dayjs) => {
    setLoading(true);
    try {
      const response = await salonServices.getSummaryDashboard(startTime.format(DATE_FORMAT), endTime.format(DATE_FORMAT));
      if (response.code === 200) {
        setSummaryDashboard(response.payload);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = ({ startDate, endDate }: IFormValues) => getDataList(startDate, endDate);

  useEffect(() => {
    getDataList(startDate, endDate);
    // eslint-disable-next-line
  }, []);

  const summaryDashboardItems = [
    {
      key: 'total_accounts', label: 'Total Account', value: summaryDashboard?.totalAccount || 0,
      icon: <AccountIcon />, background: 'rgb(17, 194, 109)',
    },
    {
      key: 'total_transactions', label: 'Total Transaction', value: summaryDashboard?.totalTransaction || 0,
      icon: <TransactionIcon />, background: 'rgb(255, 76, 82)',
    },
    {
      key: 'total_incomes', label: 'Total Incomes', value: formatCurrency(summaryDashboard?.totalIncomes || 0),
      icon: <IncomesIcon />, background: 'rgb(255, 128, 64)',
    },
    {
      key: 'total_credit_cards', label: 'Total Credit Cards', value: formatCurrency(summaryDashboard?.totalCreditCards || 0),
      icon: <CreditCardIcon />, background: 'rgb(89, 101, 238)',
    },
    {
      key: 'total_cash', label: 'Total Cash', value: formatCurrency(summaryDashboard?.totalCash || 0),
      icon: <CashIcon />, background: 'rgb(255, 205, 23)',
    },
    {
      key: 'total_gift_cards', label: 'Total Gift Card', value: formatCurrency(summaryDashboard?.totalGiftCard || 0),
      icon: <GiftCardIcon />, background: 'rgb(0, 193, 193)',
    },
    {
      key: 'total_loyalty_redeem', label: 'Loyalty Redeem', value: formatCurrency(summaryDashboard?.totalLoyaltyRedeem || 0),
      icon: <RedeemIcon />, background: 'rgb(62, 142, 247)',
    },
    {
      key: 'total_others', label: 'Others', value: formatCurrency(summaryDashboard?.totalOther || 0),
      icon: <OtherIcon />, background: 'rgb(128, 128, 192)',
    },
  ];

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <Form form={form} initialValues={{ startDate, endDate }} onFinish={handleFinish}>
        <div className="row">
          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'startDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date To'
                  onChange={setStartDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-3'>
            <div className="form-group">
              <Form.Item noStyle name={'endDate'}>
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true}
                  placeholder='Register Date From'
                  minDate={startDate}
                  onChange={setEndDate}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <button type="submit" className="btn btn-info">search</button>
            </div>
          </div>
        </div>
      </Form>

      <div className="row">
        {summaryDashboardItems.map(o => (
          <div className="col-3" key={o.key}>
            <div className="card shadow mb-4 card-dashboard" style={{ backgroundColor: o.background }}>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className='text-white'>
                    <div className="card-title" >{o.value}</div>
                    <div className="card-subtitle">{o.label}</div>
                  </div>
                  {o.icon}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-body" style={{ marginTop: '60px' }}>

              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <th className='text-center' >Business name</th>
                    <th>Email</th>
                    <th className='text-right'>Transactions</th>
                    <th className='text-right'>Incomes</th>
                    <th className='text-right'>Credit cards</th>
                    <th className='text-right'>Cash</th>
                    <th className='text-right'>Gift cards</th>
                    <th className='text-right'>Loyalty redeems</th>
                    <th className='text-right'>Others</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Hiển thị bộ Loading khi loadingLoadList là true
                    <tr className='text-center'>
                      <td colSpan={7}>
                        <span className='spinner-border spinner-border-sm mr-1'></span> loading...
                      </td>
                    </tr>
                  ) : (
                    // Hiển thị bảng dữ liệu khi loadingLoadList là false
                    <Fragment>
                      {summaryDashboard?.top10Best?.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                          <th className='text-center text-lg text-black' >{item.businessName || '--'}</th>
                          <th className='text-lg text-black'>{item.email || '--'}</th>
                          <td className='text-right text-lg text-black' ><div>{item.transactions || 0}</div></td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.incomes || 0)}</td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.creditCards || 0)}</td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.cash || 0)}</td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.giftCards || 0)}</td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.loyaltyRedeems || 0)}</td>
                          <td className='text-right text-lg text-black' >{formatCurrency(item.totalOther || 0)}</td>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalonDashboard;

const ICON_COLOR = '#fff';

const AccountIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 448 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg>
);

const TransactionIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 24 24" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M2,7 L20,7 M16,2 L21,7 L16,12 M22,17 L4,17 M8,12 L3,17 L8,22"></path></svg>
);

const IncomesIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 640 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path></svg>
);

const CreditCardIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 576 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"></path></svg>
);

const CashIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 512 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M511.1 378.8l-26.7-160c-2.6-15.4-15.9-26.7-31.6-26.7H208v-64h96c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96v64H59.1c-15.6 0-29 11.3-31.6 26.7L.8 378.7c-.6 3.5-.9 7-.9 10.5V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-90.7c.1-3.5-.2-7-.8-10.5zM280 248c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16zm-32 64h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16zm-32-80c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16zM80 80V48h192v32H80zm40 200h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16zm16 64v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16zm216 112c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h176c4.4 0 8 3.6 8 8v16zm24-112c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16zm48-80c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16z"></path></svg>
);

const GiftCardIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 512 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path></svg>
);

const RedeemIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 24 24" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7zm11.77 8.27L13 19.54l-4.27-4.27A2.5 2.5 0 0110.5 11c.69 0 1.32.28 1.77.74l.73.72.73-.73a2.5 2.5 0 013.54 3.54z"></path></svg>
);

const OtherIcon = () => (
  <svg stroke={ICON_COLOR} fill={ICON_COLOR} strokeWidth="0" viewBox="0 0 640 512" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M0 448c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V128H0v320zm448-208c0-8.84 7.16-16 16-16h96c8.84 0 16 7.16 16 16v32c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-32zm0 120c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H456c-4.42 0-8-3.58-8-8v-16zM64 264c0-4.42 3.58-8 8-8h304c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zm0 96c0-4.42 3.58-8 8-8h176c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zM624 32H16C7.16 32 0 39.16 0 48v48h640V48c0-8.84-7.16-16-16-16z"></path></svg>
);
