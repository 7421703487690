import message from 'antd/es/message';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import InputFormatPhone from 'react-phone-number-input/input'
import { agentService } from '../../services';

export const AgentEdit = () => {
  const onClose = () => {
    navigate('/agent-managment');
  };
  const navigate = useNavigate();

  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inputPhone, setPhone] = useState<any>(null);
  const [agentDetail, setChiTiet] = useState<any>(null);
  const [isChangePass, setIsChangePass] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await agentService.getDetailById(id).then((response) => {
          if (response.code === 200) {
            setChiTiet(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    if (!agentDetail) {
      fetchCongDoanData();
    }
  });

  useEffect(() => {
    if (agentDetail) {
      formEdit.setFieldsValue({
        firstName: agentDetail.firstName,
        lastName: agentDetail.lastName,
        phone: agentDetail.phone,
        email: agentDetail.email,
        address: agentDetail.address,
        state: agentDetail.state,
        city: agentDetail.city,
        zipCode: agentDetail.zipCode,
      });
      setPhone(agentDetail.phone);
    }
  }, [agentDetail, formEdit]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const objectUpdate = {
        accessToken: agentDetail.accessToken,
        address: values.address,
        balanceAmount: agentDetail.balanceAmount,
        city: values.city,
        countryName: agentDetail.countryName,
        createByAccountId: agentDetail.createByAccountId,
        createDate: agentDetail.createDate,
        email: values.email,
        firstName: values.firstName,
        id: agentDetail.id,
        isSendWarningSms1: agentDetail.isSendWarningSms1,
        isSendWarningSms2: agentDetail.isSendWarningSms2,
        lastName: values.lastName,
        numberLoginFail: agentDetail.numberLoginFail,
        password: isChangePass ? values.password : '',
        phone: inputPhone,
        registerDate: agentDetail.registerDate,
        role: agentDetail.role,
        state: values.state,
        status: agentDetail.status,
        userPresentId: agentDetail.userPresentId,
        zipCode: values.zipCode
      }
      const response = await agentService.updateAgent(objectUpdate);
      if (response.code === 200) {
        message.success('Update successful', onClose)
      } else {
        setLoading(false);
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      setLoading(false);
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between mb-4">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          <i className="fas fa-fw fa-angle-left"></i>
          Back
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card-header py-3 card-header-customer">
            <div className="group-title"><h4>Edit Agent</h4><span>Agent Infomation</span></div>
          </div>
          <div className="card shadow mb-4">
            <Form form={formEdit}
              name="detail" onFinish={onSubmit}
              labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
              <div className="card-body" style={{ marginTop: '60px' }}>
                <div className="row">
                  <div className='col-sm-12'>
                    <h5 className="h5 mb-3" style={{ fontWeight: 'bold' }}>Basic Infomation</h5>
                    <div className="row">
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="firstName"
                              label="First name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "The input is not valid E-mail !",
                                }
                              ]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="address"
                              label="Address">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="state"
                              label="State">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="lastName"
                              label="Last name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[{ required: true }]}>
                              <InputFormatPhone
                                className="form-phone-validate form-control"
                                country="US"
                                autoComplete="off"
                                name="phone"
                                onChange={e => setPhone(e)}
                                placeholder="(___)___-___" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="city"
                              label="City">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="zipCode"
                              label="Zip Code">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="ischangePassword"
                              label="Change Password">
                              <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) => setIsChangePass(checked)} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='cardPassword' style={{ display: isChangePass ? 'block' : 'none' }}>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className="form-group">
                            <div className="col-sm-12">
                              <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!getFieldValue('ischangePassword')) {
                                        return Promise.resolve();
                                      }

                                      if (getFieldValue('ischangePassword') && !value) {
                                        return Promise.reject('Please input your password!');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]} dependencies={['ischangePassword']}
                              >
                                <Input.Password
                                  size="large"
                                  placeholder="New password"
                                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className="form-group">
                            <div className="col-sm-12">
                              <Form.Item
                                name="rePassword"
                                label="Retype password"
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!getFieldValue('ischangePassword')) {
                                        return Promise.resolve();
                                      }

                                      if (getFieldValue('ischangePassword') && !value) {
                                        return Promise.reject('Please input your retype password!');
                                      }
                                      if (value && getFieldValue('password') && value !== getFieldValue('password')) {
                                        return Promise.reject('Passwords do not match!');
                                      }
                                      if (!value && getFieldValue('password')) {
                                        return Promise.reject('Please confirm your password!');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]} dependencies={['password', 'ischangePassword']}>
                                <Input.Password
                                  size="large"
                                  placeholder="Retype new password"
                                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12 text-right'>
                    <div className="form-group">
                      <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                      <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
                        {loading && (
                          <span className='spinner-border spinner-border-sm mr-1'></span>
                        )}
                        Save
                      </></button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div >
    </div >
  )
}

