import React from 'react';
import PropTypes from 'prop-types';
import {
    Row, Col, Label
} from 'reactstrap';

export default function LabelDescription({ label, description }: { label: string, description: string }) {
    return (
        <Row>
            <Label md={4}>
                {label}
            </Label>
            <Col md={8}>
                <b>{description}</b>
            </Col>
        </Row>
    );
}

LabelDescription.propTypes = {
    label: PropTypes.string,
    description: PropTypes.string,
};
