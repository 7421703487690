import message from 'antd/es/message';
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { couponService } from '../../services';

export const SettingCouponDetail = () => {
  const onClose = () => {
    navigate('/setting-coupon');
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const [accountDetail, setChiTiet] = useState<any>(null);

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await couponService.getDetailById(id).then((response) => {
          if (response.code === 200) {
            setChiTiet(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      }
    };
    if (!accountDetail) {
      fetchCongDoanData();
    }
  });

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div className='row mb-4'>
        <h1 className="h3 mb-0 col-sm-5" style={{ color: '#000', fontWeight: 'bold' }}>Coupon Detail</h1>
        <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between  col-sm-7">
          <Link to={`/setting-coupon/edit/${id}`} style={{ backgroundColor: 'rgba(38, 156, 255, 1)' }} className="btn btn-info mr-2">
            <i className="fa fa-edit mr-2"></i>
            Edit
          </Link>
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            <i className="fas fa-fw fa-angle-left mr-2"></i>
            Back
          </button>
        </div>
      </div>

      <div className="row mb-4">
        <div className='col-sm-3'></div>
        <div className='text-center col-sm-6'>
          {accountDetail == null ? (
            <Fragment>
              <span className='spinner-border spinner-border-sm mr-1'></span>Loading...
            </Fragment>
          ) : (<div className='p-2' style={{ display: 'inline-block' }}>
            <img style={{ width: '380px' }} src='https://bona-pos.s3.us-east-2.amazonaws.com/coupon-template/0ac88d44-7fe7-4f54-bd11-b44fe90eb844.png' alt='img_counpon' />
          </div>
          )
          }
        </div>
        <div className='col-sm-3'></div>
      </div>

      <div className="row">
        <div className='col-sm-2'></div>
        <div className='col-sm-8'>
          <div className='row'>
            <div className="col-xl-4 col-md-6 mb-4">
              <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                        Coupon Quantity
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {accountDetail?.quantity?.toLocaleString()}
                      </div>
                    </div>
                    <div className="col-auto">
                      <img className='my-icon' style={{ width: '40px' }} src="/images/icon-kyc.svg" alt="Icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-4">
              <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                        Printed Quantity
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {accountDetail?.totalPrint?.toLocaleString()}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-print fa-2x text-black-50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-4">
              <div className="card border-left-warning shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Coupon Used</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">{accountDetail?.totalUsed?.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <img className='my-icon' style={{ width: '40px' }} src="/images/icon-launch.svg" alt="Icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-2'></div>
      </div>
    </div>
  )
}

