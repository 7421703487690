import { api } from '../helpers';

const filterList = async (page: number | null, pageSize: number | null, discountType: string, status: any | null, couponName: any, shopId?: any) => {
    const params = { pageSize, page, discountType, status, couponName, shopId };
    return await api.get('/manage-coupon/list', { params }).then((response: any) => {
        return response.data;
    });
};

const create = async (activePrint: any, categoryName: any, couponCode: any, couponId: any, couponName: any,
    description: any, discountType: any, discountValue: any, endDate: any, image: any, quantity: any, shopId: any, startDate: any, title: any) => {
    const payLoad = { activePrint, categoryName, couponCode, couponId, couponName, description, discountType, discountValue, endDate, image, quantity, shopId, startDate, title };
    return await api.post('/manage-coupon/create', payLoad).then((response: any) => {
        return response.data;
    });
};

const updateSatus = async (couponId: number, status: String) => {
    const payLoad = { couponId, status };
    return await api.put('/manage-coupon/update-status', payLoad).then((response: any) => {
        return response.data;
    });
};

const getDetailById = async (id: any) => {
    return await api.get(`/manage-coupon/detail/${id}`).then((response: any) => {
        return response.data;
    });
};

const deleteCoupon = async (id: any) => {
    return await api.delete(`/manage-coupon/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

export const couponService = {
    filterList,
    getDetailById,
    create,
    updateSatus,
    deleteCoupon
};