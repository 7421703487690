import { DatePicker, Form, Modal, Pagination, QRCode, Select, Switch, message } from 'antd'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import Search from 'antd/es/input/Search';
import { accountService, couponService } from '../../services';
import dayjs from 'dayjs';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import SelectOrDatePicker from '../../components/SelectOrDatePicker';
import { captureDivAsImage, convertImageUrlToBase64, formatDecimal } from '../../helpers';
import { MdClose, MdCode, MdDelete, MdRemoveRedEye } from 'react-icons/md';
import UnControlledDropdownModal from '../../components/UnControlledDropdownModal';
import Swal from 'sweetalert2';

export const SettingCouponList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formSearch] = Form.useForm();
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
  const [listAllSalon, setListAllSalon] = useState<any>(null);
  const divRef = useRef(null);

  useEffect(() => {
    const initData = async () => {
      try {
        await accountService.getAllShopByFilter(null).then((response) => {
          if (response.code === 200) {
            setListAllSalon(response.payload);
          } else {
            message.error('Not get all salon');
          }
        });
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    if (!listAllSalon) {
      initData();
    }
  }, [listAllSalon]);

  const getDataList = async (pageNumber: number | null, saleOff: any, status: any | null, couponName: any, salonId: any) => {
    setLoading(true);
    try {
      const response = await couponService.filterList(pageNumber, MAX_ITEMS_PER_PAGE, saleOff, status, couponName, salonId);
      if (response.code === 200) {
        setTotal(response.payload.total);
        setLstDanhSach(response.payload.coupons);
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initData = async () => {
      setCurrentPage(1);
      getDataList(1, null, null, null, null);
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const values = await formSearch.validateFields();
    getDataList(pageNumber, `${values.saleOff}`, values.status, values.couponName, values.salonId);
  };

  const handleSelectStatusChange = async (value: string) => {
    const values = await formSearch.validateFields();
    setCurrentPage(1);
    getDataList(1, `${values.saleOff}`, value, values.couponName, values.salonId);
  };

  const handleSelectSalonChange = async (value: string) => {
    const values = await formSearch.validateFields();
    setCurrentPage(1);
    getDataList(1, `${values.saleOff}`, values.status, values.couponName, value);
  };

  const handleSelectTypeChange = async (value: string) => {
    const values = await formSearch.validateFields();
    setCurrentPage(1);
    getDataList(1, `${value}`, values.status, values.couponName, values.salonId);
  };

  const handleInputSerch = async (value: string) => {
    const values = await formSearch.validateFields();
    setCurrentPage(1);
    getDataList(1, values.saleOff, values.status, value, values.salonId);
  };

  const handleFinishFilter = (values: any) => {
    getDataList(1, `${values.saleOff}`, values.status, values.couponName, values.salonId);
  };

  const handleCloseEditorModal = () => {
    setShowModalActive(false);
    onPageChanged(currentPage);
  }
  const [showModalActive, setShowModalActive] = useState(false);
  const [formActive] = Form.useForm();
  const [objetDetail, setObjetDetail] = useState<any>(null);

  const handleSwitchChange = async (checked: any, itemId: any, startDate: any) => {
    await couponService.getDetailById(itemId).then((response) => {
      if (response.code === 200) {
        setObjetDetail(response.payload);
      } else {
        message.error(response.message);
      }
    });

    // trường hợp muốn active thì show modal 
    if (checked) {
      setShowModalActive(true);
      formActive.setFieldsValue({
        startDate: startDate
      });
      setExpireDate(dayjs(objetDetail?.endDate).format('MM-DD-YYYY'));
      setStartDateEntered(startDate);
    } else {
      // trường hợp cập nhật về pending
      const response = await couponService.updateSatus(itemId, 'PENDING');
      if (response.code === 200) {
        message.success('Update successful')
        const element = document.getElementById('td_status_' + itemId);
        if (element) {
          element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(255, 247, 232);">Pending</span>';
        }
      } else {
        message.error({ content: response.message, duration: 2 });
      }
    }
  };

  const onSubmitUpdateAcvite = async () => {
    try {
      setLoadingSubmit(true);
      const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
      const dateStartCheck = dayjs(startDate.format('MM-DD-YYYY'), { format: 'MM-DD-YYYY' });
      if (dateCheck.isBefore(dateStartCheck)) {
        message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
        setLoadingSubmit(false);
        return;
      }
      const imgBase64 = await captureDivAsImage(divRef);
      const response = await couponService.create(objetDetail.activePrint,
        objetDetail.categoryName,
        objetDetail.couponCode,
        objetDetail.id,
        objetDetail.couponName,
        objetDetail.description,
        objetDetail.discountType,
        objetDetail.discountValue,
        expireDate,
        imgBase64,
        objetDetail.quantity,
        objetDetail.shopId,
        dayjs(objetDetail.startDate).format('MM-DD-YYYY'),
        objetDetail.title
      );
      if (response.code === 200) {
        message.success('Update successful')
        setShowModalActive(false);
        setLoadingSubmit(false);
        const element = document.getElementById('td_status_' + objetDetail.id);
        if (element) {
          element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(232, 243, 255);">Active</span>';
        }
      } else {
        message.error({ content: response.message, duration: 2 });
        setLoadingSubmit(false);
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
      setLoadingSubmit(false);
    }
  };

  const [startDate, setStartDateEntered] = useState<any>(null);
  const handleStartDateChange = (date: any) => {
    setStartDateEntered(date);
  };

  const [expireDate, setExpireDate] = useState('');
  const handleChangeExpireDate = (value: any, date: any) => {
    if (!isNaN(value)) {
      value = (startDate.add(value, 'day')).format('MM-DD-YYYY')
    }
    setExpireDate(value);
  };

  const handleViewDetail = (id: any) => () => {
    navigate(`/setting-coupon/detail/${id}`);
  };

  const handleDelete = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Do you want delete coupon?',
      html: 'You can’t restore this file',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      try {
        const response = await couponService.deleteCoupon(id);
        if (response.code === 200) {
          message.success('Delete successful');
          onPageChanged(currentPage);
        } else {
          message.error({ content: response.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      }
    }
  };

  const renderActionModal = (item: any) => {
    const props = [
      {
        icon: <MdRemoveRedEye className="menu-item menu-item-detail" />,
        label: 'View',
        onClick: handleViewDetail(item.id),
      },
      {
        icon: <MdClose className="menu-item color-alert" />,
        label: 'Delete',
        onClick: handleDelete(item.id),
      },
    ];

    return (
      <UnControlledDropdownModal items={props} />
    );
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <Modal
        title='Active Coupon'
        open={showModalActive}
        onCancel={handleCloseEditorModal}
        maskClosable={false}
        footer={[]}>
        {/* Hiển thị trình soạn thảo trong modal */}
        <Form form={formActive} name="formActive"
          layout="vertical"
          onFinish={onSubmitUpdateAcvite}>
          <div className='row'>
            <Fragment>
              <div className='text-center col-sm-12'>
                <div style={{ backgroundColor: '#ffffff', display: 'inline-block', padding: '3px' }} ref={divRef}>
                  <div id='dataImage' style={{ padding: '10px', color: '#000000', backgroundColor: '#ffffff', border: '1px dashed #000', strokeDasharray: '10px', minWidth: '372px', minHeight: '253px', borderRadius: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ fontSize: '40px', fontWeight: 'bold' }}>
                          {objetDetail?.discountType === 1 ? '$' : ''}
                        </span>
                        <h1 style={{ fontSize: '125px', fontWeight: 'bold', lineHeight: '100px', marginBottom: '16px' }}>
                          {Math.floor(objetDetail?.discountValue)}
                        </h1>
                        <div>
                          <div style={{ display: 'flex' }}>
                            <span style={{ fontSize: '40px', fontWeight: 400, fontFamily: 'Inter' }}>{objetDetail?.discountType === 1 ? formatDecimal(objetDetail?.discountValue) : '%'}</span>
                            <span style={{ marginLeft: '3px', fontSize: '48px', fontWeight: 'bold', fontFamily: 'Playfair Display' }}>OFF</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <span style={{ right: '-85px', fontSize: '35px', fontWeight: 'bold', fontFamily: 'Playfair Display', lineHeight: '35px' }}>
                              {objetDetail?.couponName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', borderTop: '1px solid black', fontSize: '15px', fontWeight: 'bold' }}>
                        <span style={{ fontWeight: 'bold', flex: 1 }}>
                          <QRCode type='svg' value={objetDetail?.couponCode} bordered={false} size={115} />
                          <span>{objetDetail?.couponCode}</span>
                        </span>
                        <div style={{ paddingTop: '8px', paddingLeft: '5px', flex: 9 }}>
                          <div style={{ display: 'flex', textAlign: 'left' }}>
                            <span>* FOR YOUR NEXT VISIT ONLY</span>
                          </div>
                          <div style={{ display: 'flex', height: '50px', textAlign: 'left' }}>

                            <span>
                              <pre style={{ color: '#000000', fontSize: 'inherit', margin: '0px', fontFamily: 'Inter' }}>{objetDetail?.description}</pre>
                            </span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <span>
                              Expire Until: <strong style={{ fontSize: '17px' }}>{expireDate}</strong>
                            </span>
                          </div>
                          <div style={{ display: 'flex', textAlign: 'left' }}>
                            <span style={{ fontSize: '15px' }}>Can not combine with any other offers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>

            <div className='col-sm-12'>
              <Form.Item
                name="startDate" // Tên của trường trong form
                label='Start Date'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <DatePicker
                  onChange={handleStartDateChange}
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true} />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12'>
              <Form.Item
                name="expiresDate" // Tên của trường trong form
                label='Expires Date'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf={''} />
              </Form.Item>
            </div>
          </div>

          <div className='row text-center mt-3'>
            <div className="col-6">
              <button type="button" className="btn btn-secondary btn-block" onClick={handleCloseEditorModal}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button className="btn btn-primary btn-block" type='submit' disabled={loadingSubmit}>
                {loadingSubmit && (
                  <span className='spinner-border spinner-border-sm mr-1'></span>
                )}
                <span style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>
                  Done
                </span>
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      <Form form={formSearch} name="search" onFinish={handleFinishFilter}>
        <div className='row'>
          <div className='col-sm-2'>
            <Form.Item name="saleOff">
              <Select placeholder="Sale Off" size="large" mode='tags'>
                <Select.Option key={'1'} value={'1'}>{'Dollar'}</Select.Option>
                <Select.Option key={'0'} value={'0'}>{'Percent'}</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className='col-sm-2'>
            <Form.Item name="status">
              <Select defaultValue={''} placeholder="Select Status" size="large">
                <Select.Option key={'Select Status'} value={''}>{'All Status'}</Select.Option>
                <Select.Option key={'ACTIVE'} value={'ACTIVE'}>{'Active'}</Select.Option>
                <Select.Option key={'PENDING'} value={'PENDING'}>{'Pending'}</Select.Option>
                <Select.Option key={'EXPIRED'} value={'EXPIRED'}>{'Expired Date'}</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className='col-sm-3'>
            <Form.Item name="salonId">
              <Select defaultValue={''} placeholder="Select Salon" size="large" showSearch optionFilterProp="label">
                <Select.Option key={'All Salon'} value={''}>{'All Salon'}</Select.Option>
                {listAllSalon?.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.id} label={`${item.companyName} - ${item.city} - ${item.zip}`}>{item.companyName} - ${item.city} - ${item.zip}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className='col' style={{ flex: 1 }}>
            <Form.Item name="couponName">
              <Search placeholder="Coupon Name" size="large" />
            </Form.Item>
          </div>

          <div className='text-right' style={{ paddingRight: '0.75rem' }}>
            <button type='submit' className="btn btn-info mr-2">
              <span style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>
                Search
              </span>
            </button>
            <Link className="btn btn-primary" to={'/setting-coupon/create'}>
              <span style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>
                <i className='fas fa-plus mr-2' />
                Create Coupon
              </span>
            </Link>
          </div>
        </div>
      </Form>

      <div className="row">
        <div className="col-12">
          <div className="shadow mb-4" style={{ backgroundColor: '#ffffff' }}>
            <table className="table" width="100%" cellSpacing={0}>
              <thead>
                <tr className='text-center'>
                  <th>Category <br /> Name</th>
                  <th style={{ verticalAlign: 'middle' }}>Title</th>
                  <th>Salon  <br /> Name</th>
                  <th style={{ verticalAlign: 'middle' }}>Promotion</th>
                  <th>Printed  <br />  Quantity</th>
                  <th style={{ backgroundColor: '#fff7e8' }}>Coupon <br />  Used</th>
                  <th>Start <br />  Date</th>
                  <th>Expires <br />  Date</th>
                  <th style={{ verticalAlign: 'middle' }}>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ color: '#000000' }}>
                {loading ? (
                  // Hiển thị bộ Loading khi loadingLoadList là true
                  <tr className='text-center'>
                    <td colSpan={11}>
                      <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                    </td>
                  </tr>
                ) : (
                  // Hiển thị bảng dữ liệu khi loadingLoadList là false
                  <Fragment>
                    {lstDanhSach?.map((item, index) => (
                      <tr key={index} className='text-center'>
                        <td style={{ verticalAlign: 'middle' }}>{item.categoryName}</td>
                        <td style={{ verticalAlign: 'middle' }}>{item.couponName}</td>
                        <td style={{ verticalAlign: 'middle' }}>{item.shopName}</td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {item.discountType === 0 ? (item.discountValue.toLocaleString() + '%') : ('$' + item.discountValue.toLocaleString())}
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <div className="progress">
                            <div style={{ width: `${(item.totalPrint / item.quantity) * 100}%` }} className="progress-bar bg-warning" role="progressbar" />
                            <span>{item.totalPrint ? item.totalPrint : 0} | {item.quantity}</span>
                          </div>
                        </td>
                        <td style={{ backgroundColor: '#fff7e8', verticalAlign: 'middle' }}>
                          <strong>
                            {item.totalUsed ? item.totalUsed.toLocaleString() : 0}
                          </strong>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {dayjs(item.startDate).format('MM-DD-YYYY')}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {dayjs(item.endDate).format('MM-DD-YYYY')}
                        </td>
                        <td style={{ verticalAlign: 'middle' }} id={'td_status_' + item.id}>
                          {item.status === 'ACTIVE' ?
                            (<span className='coupon-label-status' style={{ backgroundColor: 'rgba(232, 243, 255, 1)' }}>Active</span>)
                            : item.status === 'PENDING' ?
                              (<span className='coupon-label-status' style={{ backgroundColor: 'rgba(255, 247, 232, 1)' }}>Pending</span>)
                              : (<span className='coupon-label-status' style={{ backgroundColor: 'rgba(206, 206, 206, 1)' }}>Expired date</span>)
                          }
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Form.Item>
                            <Switch
                              onChange={(checked) => handleSwitchChange(checked, item.id, dayjs(item.startDate))}
                              checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked={item.status === 'ACTIVE'} />
                          </Form.Item>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {renderActionModal(item)}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                )}
              </tbody>
            </table>
          </div>
          <div className='text-right'>
            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={MAX_ITEMS_PER_PAGE}
              onChange={onPageChanged}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
