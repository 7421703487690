import { Fragment, useState } from 'react';
import { LeftMenu } from './LeftMenu';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AuthenticatedUser } from '../../store/account/types';
import { Link } from 'react-router-dom';
import { logout } from '../../store/account/actions';

export const Admin = ({ children }: { children: React.ReactNode }) => {
    const [isShowDropdownAccount, setShowDropdownAccount] = useState(false);
    const userLogin = useSelector<AppState>((state) => state.account.user) as AuthenticatedUser;
    const dispatch = useDispatch();

    return (
        <Fragment>
            <LeftMenu admin={Number(userLogin.role) === 0} />
            {/* Content Wrapper */}
            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    {/* Topbar */}
                    <nav className="navbar nav-sidebar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                        {/* Sidebar Toggle (Topbar) */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars" />
                        </button>

                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <li className={`nav-item dropdown no-arrow ` + (isShowDropdownAccount ? 'show' : '')}>
                                <span className="nav-link dropdown-toggle"
                                    onClick={() => setShowDropdownAccount(!isShowDropdownAccount)}
                                    id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isShowDropdownAccount}>
                                    <img className="img-profile rounded-circle" src="/images/avatar-none.png" alt='avatar-none' />
                                    <span className="ml-2 d-none d-lg-inline text-gray-600 small">
                                        {userLogin.loginName}
                                    </span>
                                </span>
                                {/* Dropdown - User Information */}
                                <div className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ` + (isShowDropdownAccount ? 'show' : '')} aria-labelledby="userDropdown">
                                    <Link className="dropdown-item" data-toggle="modal" data-target="#logoutModal" to='/#' onClick={() => dispatch(logout())}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                        Logout
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    {children}
                </div>
                {/* Footer */}
                <footer className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">

                        </div>
                    </div>
                </footer>
            </div>
            {/* End of Content Wrapper */}
        </Fragment>
    );
};
