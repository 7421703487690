import message from 'antd/es/message';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { accountService } from '../../services/account.service';
import { Form, Input, Select } from 'antd';
import timeZoneData from '../../mockdata/_timeZone.json'
import InputFormatPhone from 'react-phone-number-input/input'
import { KEY_ID_ACCOUNT_SELECED } from '../../helpers/constants';

export const AccountOwnerEdit = () => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(`/account-summary/detail/${localStorage.getItem(KEY_ID_ACCOUNT_SELECED)}`);
  };

  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [shopDetail, setChiTiet] = useState<any>(null);
  const [inputPhone, setPhone] = useState<any>(null);

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await accountService.getShopDetailById(id).then((response) => {
          if (response.code === 200) {
            setChiTiet(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    if (!shopDetail) {
      fetchCongDoanData();
    }
  });

  useEffect(() => {
    if (shopDetail) {
      formEdit.setFieldsValue({
        companyName: shopDetail.companyName,
        city: shopDetail.city,
        email: shopDetail.email,
        timeZone: shopDetail.timeZone,
        address: shopDetail.address,
        phone: shopDetail.phone,
        state: shopDetail.state,
        zip: shopDetail.zip
      });
      setPhone(shopDetail.phone);
    }
  }, [shopDetail, formEdit]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const objectUpdate = {
        accountId: shopDetail.accountId,
        address: values.address,
        apiKey: shopDetail.apiKey,
        city: values.city,
        companyName: values.companyName,
        countryId: shopDetail.countryId,
        createByAccountId: shopDetail.createByAccountId,
        createDate: shopDetail.createDate,
        email: values.email,
        id: shopDetail.id,
        isLinkBooking: shopDetail.isLinkBooking,
        isRandomTicketNumber: shopDetail.isRandomTicketNumber,
        linkShareFormCovid19: shopDetail.linkShareFormCovid19,
        phone: inputPhone,
        shopId: shopDetail.id,
        state: values.state,
        status: shopDetail.status,
        timeZone: values.timeZone,
        urlImageReviews: shopDetail.urlImageReviews,
        urlImageSignIn: shopDetail.urlImageSignIn,
        uuid: shopDetail.uuid,
        zip: values.zip,
      }

      const response = await accountService.updateShopById(objectUpdate);
      if (response.code === 200) {
        message.success('Update successful', onClose)
      } else {
        setLoading(false);
        message.error({ content: response.message, duration: 2 });
      }

    } catch (error: any) {
      setLoading(false);
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between mb-4">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          <i className="fas fa-fw fa-angle-left"></i>
          Back
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card-header py-3 card-header-customer">
            <div className="group-title"><h4>Update Owner</h4><span>Information Owner Shop</span></div>
          </div>
          <div className="card shadow mb-4">
            <Form form={formEdit}
              name="detail" onFinish={onSubmit}
              labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
              <div className="card-body" style={{ marginTop: '60px' }}>
                <div className="row">
                  <div className='col-sm-12'>
                    <div className="row">
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="companyName"
                              label="Shop name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="city"
                              label="City"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "The input is not valid E-mail !",
                                }
                              ]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="timeZone"
                              label="Time Zone">
                              <Select placeholder="Time Zone" size="large">
                                <Select.Option key={''} value={''}>{''}</Select.Option>
                                {timeZoneData.map((item: any, index) => (
                                  <Select.Option key={index} value={item.value}>{item.text}</Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="address"
                              label="Address"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[{ required: true }]}>
                              <InputFormatPhone
                                className="form-phone-validate form-control"
                                country="US"
                                autoComplete="off"
                                name="phone"
                                onChange={e => setPhone(e)}
                                placeholder="(___)___-___" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="state"
                              label="State">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="zip"
                              label="Zip Code">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12 text-right'>
                    <div className="form-group">
                      <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                      <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
                        {loading && (
                          <span className='spinner-border spinner-border-sm mr-1'></span>
                        )}
                        Save
                      </></button>
                    </div>
                  </div>
                </div>
              </div>


            </Form>
          </div>
        </div>
      </div >
    </div >
  )
}

