import React, { Fragment, useEffect, useState } from 'react'
import { accountService, agentService } from '../../services';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { DatePicker, Form, Input, Modal, Pagination, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';
import { MdClose, MdModeEdit } from 'react-icons/md';
import { GiPriceTag } from 'react-icons/gi';
import UnControlledDropdownModal from '../../components/UnControlledDropdownModal';
import Swal from 'sweetalert2';

export const AgentManageList = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

    const [loadingModal, setLoadingModal] = useState(false);
    const [formPriceSms] = Form.useForm();
    const [showPriceSmsModal, setShowPriceSmsModal] = useState(false);
    const [objectPriceSmsModal, setObjectPriceSmsModal] = useState<any>(null);
    const [nameSelect, setNameSelect] = useState('');

    const getDataList = async (pageNumber: number, startTime: any | null, endTime: any, keyword: any) => {
        setLoading(true);
        try {
            const response = await agentService.filterList(pageNumber, MAX_ITEMS_PER_PAGE, startTime, endTime, keyword);
            if (response.code === 200) {
                setTotal(response.payload.total);
                setLstDanhSach(response.payload.agents);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initData = async () => {
            setCurrentPage(1);
            getDataList(1, null, null, null);
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
        const values = await form.validateFields();
        getDataList(pageNumber, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
    };

    const handleSearch = async () => {
        const values = await form.validateFields();
        setCurrentPage(1);
        getDataList(1, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
    };

    const handleEdit = (id: any) => () => {
        navigate(`/agent-managment/edit/${id}`);
    };

    const handleSMS = (id: any, name: string) => async () => {
        const response = await accountService.getPriceSmsByAccount(id);
        if (response.code === 200) {
            setObjectPriceSmsModal(response.payload);
            setNameSelect(name);
        } else {
            message.error({ content: response.message, duration: 2 });
        }
    };

    const handleDelete = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Are you sure want to delete this Agent?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            try {
                const response = await agentService.deleteAgent(id);
                if (response.code === 200) {
                    message.success('Delete successful');
                    const values = await form.validateFields();
                    getDataList(currentPage, values.startTime?.format('YYYY-MM-DD 12:00:00'), values.endTime?.format('YYYY-MM-DD 12:00:00'), values.keyword?.trim());
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            }
        }
    };

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <MdModeEdit className="menu-item color-main" />,
                label: 'Edit',
                onClick: handleEdit(item.id),
            },
            {
                icon: <MdClose className="menu-item color-alert" />,
                label: 'Delete',
                onClick: handleDelete(item.id),
            },
            {
                icon: <GiPriceTag className="menu-item menu-item-extend" />,
                label: 'Price SMS',
                onClick: handleSMS(item.id, `${item.firstName} ${item.lastName}`),
            },
        ];
        return (
            <UnControlledDropdownModal items={props} />
        );
    };

    const handleCloseEditorModal = () => {
        setShowPriceSmsModal(false);
        formPriceSms.resetFields();
        setObjectPriceSmsModal(null);
        setNameSelect('');
    }

    useEffect(() => {
        if (objectPriceSmsModal) {
            formPriceSms.setFieldsValue({
                name: nameSelect,
                pricing: objectPriceSmsModal.pricing,
            });
            setShowPriceSmsModal(true);
        }
    }, [objectPriceSmsModal, formPriceSms, nameSelect]);

    const onSubmitPriceSms = async (values: any) => {
        try {
            setLoadingModal(true);

            const objectUpdate = {
                accountId: objectPriceSmsModal.accountId,
                priceSms: values.pricing
            }
            const response = await agentService.updatePriceSms(objectUpdate);
            if (response.code === 200) {
                message.success('Change successful');
                setShowPriceSmsModal(false);
                formPriceSms.resetFields();
                setObjectPriceSmsModal(null);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoadingModal(false);
        }
    };

    return (
        <div className="container-fluid" style={{ paddingTop: '10px' }}>
            <Modal
                title='PRICE SMS'
                open={showPriceSmsModal}
                onCancel={handleCloseEditorModal}
                maskClosable={false}
                footer={[]}>
                {/* Hiển thị trình soạn thảo trong modal */}
                <Form form={formPriceSms} name="formPriceSms"
                    labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={onSubmitPriceSms}>
                    <Form.Item
                        name="name"
                        label="Owner Name">
                        <Input readOnly disabled />
                    </Form.Item>
                    <Form.Item name="pricing"
                        label='Price'
                        rules={[{ required: true }]}>
                        <Input autoComplete="off" type="number" />
                    </Form.Item>

                    <div className="form-group text-right">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseEditorModal}>Cancel</button>
                        <button type="submit" className="btn btn-success ml-2" disabled={loadingModal}>
                            Change
                        </button>
                    </div>
                </Form>
            </Modal>

            <Form form={form} name="search">
                <div className="row">
                    <div className='col-sm-3'>
                        <div className="form-group">
                            <Form.Item name="startTime">
                                <DatePicker
                                    size="large"
                                    format="DD/MM/YYYY"
                                    showTime={false}
                                    inputReadOnly={true}
                                    placeholder='Register Date To'
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='col-sm-3'>
                        <div className="form-group">
                            <Form.Item name="endTime">
                                <DatePicker
                                    size="large"
                                    format="DD/MM/YYYY"
                                    showTime={false}
                                    inputReadOnly={true}
                                    placeholder='Register Date From'
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='col-sm-6'>
                        <div className="form-group">
                            <Form.Item name="keyword">
                                <Input className='form-control' placeholder="Name, Phone or Email" autoComplete="off" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="row">
                <div className="col-12">
                    <div className="card-header py-3 card-header-customer">
                        <div className="group-title"><h4>Agent managment</h4><span>Agent list</span></div>
                        <div className="group-title">
                            <Link type="button" className="btn-container-create btn" to={'/agent-managment/create'}>
                                <i className="fas fa-fw fa-plus"></i>
                                Add Agent
                            </Link>
                            <button type="button" onClick={handleSearch} className="btn-container-create btn ml-2">
                                <i className="fas fa-fw fa-search"></i>
                                Search
                            </button>
                        </div>
                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-body" style={{ marginTop: '60px' }}>

                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Order</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Create date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={7}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td className='text-center'>{index + 1 + (currentPage - 1) * 10}</td>
                                                    <td>{item.firstName} {item.lastName}</td>
                                                    <td>{item.email}</td>
                                                    <td>{formatPhoneNumber(item.phone)}</td>
                                                    <td>{item.address}</td>
                                                    <td>
                                                        {dayjs(item.createDate).format('MMM DD YYYY HH:mm')}
                                                    </td>
                                                    <td className="text-center">
                                                        {renderActionModal(item)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Pagination
                            current={currentPage}
                            total={totalItems}
                            pageSize={MAX_ITEMS_PER_PAGE}
                            onChange={onPageChanged}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
