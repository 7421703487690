import { api } from '../helpers';

const filterList = async (page: number | null, pageSize: number | null, startTime: any | null, endTime: any, keyword: any) => {
    const params = { pageSize, page, startTime, endTime, keyword };
    return await api.get('/admin/getAllAccount', { params }).then((response: any) => {
        return response.data;
    });
};

const getDetailById = async (id: any) => {
    return await api.get(`/admin/detail/${id}`).then((response: any) => {
        return response.data;
    });
};

const getShopListById = async (id: any) => {
    return await api.get(`/shop/list/${id}`).then((response: any) => {
        return response.data;
    });
};

const getShopDetailById = async (id: any) => {
    return await api.get(`/shop/detail/${id}`).then((response: any) => {
        return response.data;
    });
};

const updateAccountById = async (id: any, payload: any) => {
    const data = {
        payload
    };
    return await api.put(`/admin/update/${id}`, data).then((response) => {
        return response.data;
    });
};

const updateShopById = async (payload: any) => {
    const data = {
        type: 'ICHECKINS/ACCOUNT_SUMMARY/UPDATE_OWNER_ACCOUNT_SUMMARY',
        payload
    };
    return await api.put(`/shop/update`, data).then((response) => {
        return response.data;
    });
};

const addShopById = async (payload: any) => {
    const data = {
        type: 'ICHECKINS/ACCOUNT_SUMMARY/CREATE_OWNER_ACCOUNT_SUMMARY',
        payload
    };
    return await api.post(`/shop/addByAccountId`, data).then((response) => {
        return response.data;
    });
};

const deleteShop = async (id: any) => {
    return await api.get(`/shop/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const deleteAccount = async (id: any) => {
    return await api.delete(`/admin/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const lockAccount = async (id: any) => {
    return await api.delete(`/admin/lock/${id}`).then((response: any) => {
        return response.data;
    });
};

const resetPassAccount = async (id: any) => {
    return await api.put(`/admin/resetPassword/${id}`).then((response: any) => {
        return response.data;
    });
};

const createAccount = async (payload: any) => {
    const data = {
        type: 'ICHECKINS/ACCOUNT_SUMMARY/CREATE_ACCOUNT_SUMMARY',
        payload
    };
    return await api.post(`/admin/createAccountBusiness`, data).then((response) => {
        return response.data;
    });
};

const extendSms = async (id: any, payload: any) => {
    const data = {
        payload
    };
    return await api.post(`/admin/extendSms/${id}`, data).then((response) => {
        return response.data;
    });
};

const getPriceSmsByAccount = async (id: any) => {
    return await api.get(`/admin/getPriceSmsByAccount/${id}`).then((response: any) => {
        return response.data;
    });
};

const updatePriceSms = async (payload: any) => {
    const data = {
        type: "ICHECKINS/ACCOUNT_SUMMARY/UPDATE_PRICE_ACCOUNT_SUMMARY",
        payload
    };
    return await api.put(`/admin/updatePriceSms`, data).then((response: any) => {
        return response.data;
    });
};

const getAllShopByFilter = async (keySearch: any) => {
    const params = { keySearch };
    return await api.get(`/shop/getAllShopByFilter`, { params }).then((response: any) => {
        return response.data;
    });
};

export const accountService = {
    filterList,
    getDetailById,
    getShopListById,
    updateAccountById,
    getShopDetailById,
    updateShopById,
    addShopById,
    deleteShop,
    createAccount,
    deleteAccount,
    lockAccount,
    resetPassAccount,
    extendSms,
    getPriceSmsByAccount,
    updatePriceSms,
    getAllShopByFilter
};