import { api } from '../helpers';

const filterList = async (page: number | null, pageSize: number | null, keyword: any) => {
    const params = { pageSize, page, keyword };
    return await api.get('/admin/smsManager', { params }).then((response: any) => {
        return response.data;
    });
};

const filterDetailList = async (id: any, page: number | null, pageSize: number | null, startTime: any, endTime: any) => {
    const params = { pageSize, page, startTime, endTime };
    return await api.get(`/admin/getReportSmsByShop/${id}`, { params }).then((response: any) => {
        return response.data;
    });
};

export const smsService = {
    filterList,
    filterDetailList
};