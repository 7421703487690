import { AccountState } from '../store/account/types';
import { AppState } from '../store';
import { Login } from '../pages/login/Login'; // Đảm bảo đường dẫn đúng
import { Admin } from '../pages/admin/Admin';
import { useSelector } from 'react-redux';
import { AccountManage } from '../pages/admin/AccountManage.List';
import SalonDashboard from '../pages/admin/SalonDashboard';

export const AccountRoute = () => {
  const account: AccountState = useSelector((state: AppState) => state.account);
  return account.token ? <Admin>{Number(account.user?.role) === 0 ? <SalonDashboard /> : <AccountManage />}</Admin> : <Login />;
};
