import html2canvas from "html2canvas";

// randomUtils.ts
const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomNumberString = (length: number): string => {
    if (length <= 0) {
        throw new Error('Độ dài chuỗi phải lớn hơn 0');
    }

    let randomNumberString = '';
    for (let i = 0; i < length; i++) {
        const randomNumber = getRandomNumber(0, 9); // Số ngẫu nhiên từ 0 đến 9
        randomNumberString += randomNumber.toString();
    }
    return randomNumberString;
};


export const formatDecimal = (number: any) => {
    number = number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const numberString = number.toString();
    const decimalPart = numberString.split('.')[1];
    return decimalPart ? decimalPart : '00';
}

export const captureDivAsImage = async (divRef: React.RefObject<HTMLDivElement> | null): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
        if (divRef && divRef.current) {
            html2canvas(divRef.current)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
                    resolve(imgData);
                })
                .catch((error) => {
                    reject(error);
                });
        } else {
            reject(new Error('Không tìm thấy divRef'));
        }
    });
};

export const convertImageUrlToBase64 = (imageUrl: string): Promise<string> => {
    return fetch(imageUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Không thể tải hình ảnh');
            }
            return response.blob();
        })
        .then((blob) => {
            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (typeof reader.result === 'string') {
                        resolve(reader.result);
                    } else {
                        reject(new Error('Lỗi chuyển đổi thành chuỗi base64'));
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        });
};