import {
    AccountActionTypes,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOG_OUT,
} from './types';

import { userService } from './../../services/user.service';

export const login = (email: string, password: string) => {
    return async (dispatch: React.Dispatch<AccountActionTypes>) => {
        dispatch({
            type: LOGIN_REQUEST,
            payload: {
                email: email,
                password: password,
            },
        });
        try {
            const response = await userService.authenticate(email, password);
            if (response.code === 200) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        token: response.payload.accessToken,
                        user: response.payload
                    },
                });
            } else {
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: {
                        error: response.message,
                        loading: false
                    },
                });
            }
        } catch (error: any) {
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.toString();
            dispatch({
                type: LOGIN_FAILURE,
                payload: {
                    error: message,
                    loading: false
                },
            });
        }
    };
};

export const logout = (): AccountActionTypes => {
    return { type: LOG_OUT };
};