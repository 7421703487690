import React, { Fragment, useEffect, useState } from 'react'
import { smsService } from '../../services';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { Form, Input, Pagination, message } from 'antd';
import { formatPhoneNumber } from 'react-phone-number-input';
import { MdRemoveRedEye } from 'react-icons/md';
import UnControlledDropdownModal from '../../components/UnControlledDropdownModal';
import { useNavigate } from 'react-router-dom';

export const SmsManageList = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const navigate = useNavigate();

    const getDataList = async (pageNumber: number, keyword: any) => {
        setLoading(true);
        try {
            const response = await smsService.filterList(pageNumber, MAX_ITEMS_PER_PAGE, keyword);
            if (response.code === 200) {
                setTotal(response.payload.total);
                setLstDanhSach(response.payload.data);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initData = async () => {
            setCurrentPage(1);
            getDataList(1, null);
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
        const values = await form.validateFields();
        getDataList(pageNumber, values.keyword?.trim());
    };

    const handleSearch = async () => {
        const values = await form.validateFields();
        setCurrentPage(1);
        getDataList(1, values.keyword?.trim());
    };

    const handleViewDetail = (id: any) => () => {
        navigate(`/sms-managment/detail/${id}`);
    };

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <MdRemoveRedEye className="menu-item menu-item-detail" />,
                label: 'View Detail',
                onClick: handleViewDetail(item.shopId),
            }
        ];
        return (
            <UnControlledDropdownModal items={props} />
        );
    };

    return (
        <div className="container-fluid" style={{ paddingTop: '10px' }}>
            <Form form={form} name="search">
                <div className="row">
                    <div className='col-sm-6'>
                        <div className="form-group">
                            <Form.Item name="keyword">
                                <Input className='form-control' placeholder="Shop name or Phone" autoComplete="off" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="row">
                <div className="col-12">
                    <div className="card-header py-3 card-header-customer">
                        <div className="group-title"><h4>SMS Managment</h4><span>SMS list</span></div>
                        <div className="group-title">
                            <button type="button" onClick={handleSearch} className="btn-container-create btn ml-2">
                                <i className="fas fa-fw fa-search"></i>
                                Search
                            </button>
                        </div>
                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-body" style={{ marginTop: '60px' }}>
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Order</th>
                                        <th>Shop name</th>
                                        <th>Phone</th>
                                        <th>Balance Amount</th>
                                        <th>Total SMS</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td className='text-center'>{index + 1 + (currentPage - 1) * 10}</td>
                                                    <td>{item.shopName}</td>
                                                    <td>{formatPhoneNumber(item.phone)}</td>
                                                    <td>{item.balanceAmount}</td>
                                                    <td>
                                                        {item.totalSmsUsed}
                                                    </td>
                                                    <td className="text-center">
                                                        {renderActionModal(item)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Pagination
                            current={currentPage}
                            total={totalItems}
                            pageSize={MAX_ITEMS_PER_PAGE}
                            onChange={onPageChanged}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
