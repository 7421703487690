import { api } from '../helpers';

const authenticate = async (email: string, password: string) => {
    const payload = {
        type: 'ICHECKINS/LOGIN/ON_LOGIN',
        payload: {
            email,
            password,
        },
    };
    return await api.post('/admin/login', payload).then((response) => {
        return response.data;
    });
};

const logout = () => {
    sessionStorage.removeItem('user');
};


export const userService = {
    authenticate,
    logout
};