import { Tabs, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import SalonInfoTab from './SalonInfo.tab';
import StaffTab from './Staff.tab';
import CustomerTab from './Customer.tab';
import CouponTab from './Coupon.tab';
import ReportTab from './Report.tab';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IDetailSalonInfo } from './types';
import { accountService } from '../../../services';
import CheckInCouponTab from './CheckInCoupon.tab';
import AppointmentTab from './Appointment.tab';

const ShopDashboard = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(`/account-summary`);

  const { id = '' } = useParams();
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = React.useState<IDetailSalonInfo | null>(null);

  const [searchParams, setParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState(searchParams.get('tab') || 'salon_info');

  useEffect(() => setParams({ tab: activeKey }), [activeKey]);

  const fetchDetail = async () => {
    setLoading(true);
    try {
      await accountService.getShopDetailById(id).then((response) => {
        if (response.code === 200) {
          setDetail(response.payload);
        } else if (response.status === 404) {
          message.error('Id not found', onClose);
        } else {
          message.error(response.message, onClose);
        }
      });
    } catch (error: any) {
      message.error(error.message, onClose);
    } finally {
      setLoading(false);
    }
  };
  const items = useMemo(() => {
    return ([
      {
        label: `Salon info`,
        key: 'salon_info',
        children: <SalonInfoTab detail={detail} fetchDetail={fetchDetail} />,
      },
      {
        label: `Staffs`,
        key: 'staff',
        children: <StaffTab />,
        disabled: true,
      },
      {
        label: `Customers`,
        key: 'customer',
        children: <CustomerTab detail={detail} />,
      },
      {
        label: `Coupons Check-in`,
        key: 'coupon_check_in',
        children: <CheckInCouponTab detail={detail} />,
      },
      {
        label: `Coupons`,
        key: 'coupon',
        children: <CouponTab detail={detail} />,
      },
      {
        label: `Appointments`,
        key: 'appointment',
        children: <AppointmentTab detail={detail} />,
      },
      {
        label: `Report`,
        key: 'report',
        children: <ReportTab />,
        disabled: true,
      },
    ])
  }, [detail]);

  useEffect(() => { fetchDetail() }, []);

  if (loading) return (
    <>
      <span className='spinner-border spinner-border-sm mr-1'></span> Loading...
    </>
  )

  return (
    <div className="container-fluid">
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={setActiveKey}
        type="card"
        size={'large'}
        items={items}
        className='shop-dashboard-tabs'
      />
    </div>
  )
}

export default ShopDashboard;
