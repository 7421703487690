import { api } from '../helpers';

export interface ISummaryDashboard {
  totalAccount: number;
  totalTransaction: number;
  totalIncomes: number;
  totalCreditCards: number;
  totalCash: number;
  totalGiftCard: number;
  totalLoyaltyRedeem: number;
  totalOther: number;
  top10Best: Top10Best[];
}

export interface Top10Best {
  businessName: string;
  email: string;
  transactions: number;
  incomes: number;
  creditCards: number;
  cash: number;
  giftCards: number;
  loyaltyRedeems: number;
  totalOther: number;
}

type IResponseBody = {
  code: number, payload: ISummaryDashboard, message: string;
}

const getSummaryDashboard = async (startTime: string, endTime: string): Promise<IResponseBody> => {
  return await api.get(`admin/dashboard/summary?startTime=${startTime}&endTime=${endTime}`).then((response: any) => {
    return response.data;
  });
};

export const salonServices = {
  getSummaryDashboard,
};