import message from 'antd/es/message';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { accountService, couponService } from '../../services';
import { captureDivAsImage, formatDecimal } from '../../helpers';
import { Button, DatePicker, Divider, Form, Input, InputRef, QRCode, Select, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import promotionNameData from '../../mockdata/_promotion_name.json'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import SelectOrDatePicker from '../../components/SelectOrDatePicker';
import TextArea from 'antd/es/input/TextArea';

export const SettingCouponEdit = () => {
  const { Option } = Select;
  const onClose = () => {
    navigate(`/setting-coupon/detail/${id}`);
  };
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [objetDetail, setObjetDetail] = useState<any>(null);
  const [listAllSalon, setListAllSalon] = useState<any>(null);
  const divRef = useRef(null);
  const [formEdit] = Form.useForm();

  const [promotionItems, setPromotionItems] = useState<any>([]);
  const inputRef = useRef<InputRef>(null);
  const [newPromotionItems, setNewPromotionItems] = useState('');
  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    if (!newPromotionItems) {
      return;
    }
    promotionItems.unshift(newPromotionItems);
    setPromotionItems([...promotionItems]);
    setNewPromotionItems('');
  };
  const onNewPromotionItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPromotionItems(event.target.value);
  };

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await accountService.getAllShopByFilter(null).then((response) => {
          if (response.code === 200) {
            setListAllSalon(response.payload);
          } else {
            message.error('Not get all salon', onClose);
          }
        });

        await couponService.getDetailById(id).then((response) => {
          if (response.code === 200) {
            setObjetDetail(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      }
    };
    if (!objetDetail && !listAllSalon) {
      fetchCongDoanData();
    }
  });

  const [discountType, setDiscountType] = useState();
  const handleChangeDiscountType = (value: any) => {
    setDiscountType(value);
  };

  const [discountValue, setDiscountValue] = useState(0.00);
  const handleChangeDiscountValue = (event: any) => {
    const value = event.target.value;
    setDiscountValue(value);
  };

  const [title, setTitle] = useState('Title');
  const handleChangeTitle = (event: any) => {
    const value = event.target.value;
    setTitle(value);
  };

  const [startDate, setStartDateEntered] = useState<any>(null);
  const handleStartDateChange = (date: any) => {
    setStartDateEntered(date);
  };

  const [expireDate, setExpireDate] = useState('');
  const handleChangeExpireDate = (value: any, date: any) => {
    if (!isNaN(value)) {
      value = (startDate.add(value, 'day')).format('MM-DD-YYYY')
    }
    setExpireDate(value);
  };

  const [couponCode, setCouponCode] = useState<any>();
  const [description, setDescription] = useState('Description');
  const handleChangeDescription = (event: any) => {
    const value = event.target.value;
    setDescription(value);
  };

  const selectAfter = (
    <Select value={discountType} onChange={handleChangeDiscountType}>
      <Option value={1}>Dollar</Option>
      <Option value={0}>Percent</Option>
    </Select>
  );

  useEffect(() => {
    if (objetDetail) {
      formEdit.setFieldsValue({
        salonId: objetDetail.shopId,
        promotionName: objetDetail.categoryName,
        title: objetDetail.couponName,
        promotionValue: objetDetail.discountValue,
        description: objetDetail.description,
        startDate: dayjs(objetDetail.startDate),
        expiresDate: dayjs(objetDetail.endDate).format('MM-DD-YYYY'),
        couponQuantity: objetDetail.quantity,
        activePrint: objetDetail.activePrint
      });
      setDiscountType(objetDetail.discountType);
      setDiscountValue(objetDetail.discountValue);
      setTitle(objetDetail.couponName);
      setDescription(objetDetail.description);
      setExpireDate(dayjs(objetDetail.endDate).format('MM-DD-YYYY'))
      setCouponCode(objetDetail.couponCode)
      setStartDateEntered(dayjs(objetDetail.startDate));
      setLoading(false);

      promotionNameData.map((item: any) => (
        promotionItems.push(item.value)
      ))

      if (!promotionItems.includes(objetDetail.categoryName)) {
        promotionItems.unshift(objetDetail.categoryName)
      }
    }
  }, [objetDetail, formEdit]);


  const onSubmit = async (values: any) => {
    setLoadingSubmit(true);
    try {
      const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
      const dateStartCheck = dayjs(startDate.format('MM-DD-YYYY'), { format: 'MM-DD-YYYY' });
      if (dateCheck.isBefore(dateStartCheck)) {
        message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
        setLoadingSubmit(false);
        return;
      }
      // const imgBase64 = await captureDivAsImage(divRef);
      const imgBase64 = '';
      const response = await couponService.create(values.activePrint ? true : false,
        values.promotionName,
        couponCode,
        id,
        title,
        description,
        discountType,
        discountValue,
        expireDate,
        imgBase64,
        values.couponQuantity,
        values.salonId,
        startDate.format('MM-DD-YYYY'),
        title);
      if (response.code === 200) {
        message.success('Update successful', onClose)
      } else {
        setLoadingSubmit(false);
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      setLoadingSubmit(false);
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div className='row mb-4'>
        <h1 className="h3 mb-0 col-sm-5" style={{ color: '#000', fontWeight: 'bold' }}>Coupon Edit</h1>
        <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between  col-sm-7">
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            <i className="fas fa-fw fa-angle-left mr-2"></i>
            Back
          </button>
        </div>
      </div>

      <div className="row mb-4">
        <div className='col-sm-3'></div>
        <div className='text-center col-sm-6'>
          {loading ? (
            <Fragment>
              <span className='spinner-border spinner-border-sm mr-1'></span>Loading...
            </Fragment>
          ) : (
            <Fragment>
              <div style={{ backgroundColor: '#ffffff', display: 'inline-block', padding: '3px' }} ref={divRef}>
                <div id='dataImage' style={{ padding: '10px', color: '#000000', backgroundColor: '#ffffff', border: '1px dashed #000', strokeDasharray: '10px', minWidth: '372px', minHeight: '253px', borderRadius: '5px' }}>
                  <img src='https://bona-pos.s3.us-east-2.amazonaws.com/coupon-template/0ac88d44-7fe7-4f54-bd11-b44fe90eb844.png' />
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div className='col-sm-3'></div>
      </div>

      <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Form
          form={formEdit}
          onFinish={onSubmit}
          layout="vertical">
          <div className="row">
            <div className='col-sm-6'>
              <Form.Item
                name="salonId"
                label="Salon"
                rules={[
                  {
                    required: true
                  }
                ]}>
                {loading ? (
                  <span className='spinner-border spinner-border-sm mr-1'></span>
                ) : (
                  <Select placeholder="Select Salon" size="large" showSearch optionFilterProp="label">
                    {listAllSalon.map((item: any, index: number) => (
                      <Select.Option key={index} value={item.id} label={`${item.companyName} - ${item.city} - ${item.zip}`}>{item.companyName} - {item.city} - {item.zip}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className='col-sm-6'>
              <Form.Item name="activePrint" label='Activate Print Coupon' valuePropName="checked">
                <Switch title='Activate Print Coupon' defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <Form.Item
                name="promotionName"
                label="Promotion Name">
                <Select
                  placeholder="Select Promotion Name" size="large" showSearch optionFilterProp="label"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px', textAlign: 'center' }}>
                        <Input
                          size="large"
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={newPromotionItems}
                          onChange={onNewPromotionItems}
                        />
                        <Button className='btn-info' type="text" size="large" onClick={addItem}>
                          <i className="fas fa-plus mr-2"></i>Add item
                        </Button>
                      </Space>
                    </>
                  )}>
                  <Select.Option key={''} value={''}>{''}</Select.Option>
                  {promotionItems?.map((item: any) => (
                    <Select.Option key={item} value={item} label={item}>{item}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-6'>
              <Form.Item
                name="startDate" // Tên của trường trong form
                label='Start Date'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <DatePicker
                  onChange={handleStartDateChange}
                  size="large"
                  format="MM-DD-YYYY"
                  showTime={false}
                  inputReadOnly={true} />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <Form.Item
                name="title" // Tên của trường trong form
                label='Title'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Input size="large" placeholder='Please enter..' onChange={handleChangeTitle} />
              </Form.Item>
            </div>
            <div className='col-sm-6'>
              {expireDate && (<Form.Item
                name="expiresDate" // Tên của trường trong form
                label='Expires Date'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf={expireDate} />
              </Form.Item>)}
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <Form.Item
                name="promotionValue"
                label="Promotion"
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Input onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} placeholder='0' type='number' size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
              </Form.Item>
            </div>
            <div className='col-sm-6'>
              <Form.Item
                name="couponQuantity"
                label="Coupon Quantity"
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Input onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} placeholder='0' type='number' size="large" />
              </Form.Item>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <Form.Item
                name="description"
                label="Text"
              >
                <TextArea placeholder='Please enter..' showCount maxLength={100} onChange={handleChangeDescription} value={description} />
              </Form.Item>
            </div>
          </div>

          <div className='text-center mt-5'>
            <div className="form-group">
              <button type="button" style={{ minWidth: '150px' }} className="btn btn-secondary mr-2" onClick={onClose}>
                Back
              </button>
              <button className="btn btn-primary" style={{ minWidth: '150px' }} type='submit' disabled={loadingSubmit}>
                {loadingSubmit && (
                  <span className='spinner-border spinner-border-sm mr-1'></span>
                )}
                <span style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px' }}>
                  Done
                </span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

