import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { customerService } from '../../services/customer.service';
import { Form, Input, Select } from 'antd';
import timeZoneData from '../../mockdata/_timeZone.json';
import InputFormatPhone from 'react-phone-number-input/input';

const CustomerDetail = () => {
  const _location = useLocation();
  const navigate = useNavigate();
  const onClose = () => _location.state?.path ? navigate(_location.state?.path) : navigate(-1);
  
  const [inputPhone, setPhone] = useState<any>(null);

  const { shop_api_key = '', customer_id = '' } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [detail, setDetail] = useState<ICustomerDetail | null>(null);

  const [formEdit] = Form.useForm();

  console.log('shop_api_key', shop_api_key);
  console.log('customer_id', customer_id);

  const fetchDetail = async () => {
    setLoading(true);
    try {
      const res = await customerService.getCustomerDetailById(shop_api_key)(customer_id);
      console.log('res', res);
      if (res.code === 200) {
        setDetail(res.payload);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values: any) => {
    console.log(values);
  };

  useEffect(() => { fetchDetail(); }, []);

  if (loading) return (
    <>
      <span className='spinner-border spinner-border-sm mr-1'></span> Loading...
    </>
  )

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div className='row mb-4'>
        <h1 className="h3 mb-0 col-sm-5" style={{ color: '#000', fontWeight: 'bold' }}>Customer Detail</h1>
        <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between  col-sm-7">
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            <i className="fas fa-fw fa-angle-left mr-2"></i>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <Form form={formEdit}
              name="detail" onFinish={onSubmit}
              labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
              <div className="card-body" style={{ marginTop: '60px' }}>
                <div className="row">
                  <div className='col-sm-12'>
                    <div className="row">
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="companyName"
                              label="Shop name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="city"
                              label="City"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "The input is not valid E-mail !",
                                }
                              ]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="timeZone"
                              label="Time Zone">
                              <Select placeholder="Time Zone" size="large">
                                <Select.Option key={''} value={''}>{''}</Select.Option>
                                {timeZoneData.map((item: any, index) => (
                                  <Select.Option key={index} value={item.value}>{item.text}</Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="address"
                              label="Address"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[{ required: true }]}>
                              <InputFormatPhone
                                className="form-phone-validate form-control"
                                country="US"
                                autoComplete="off"
                                name="phone"
                                onChange={e => setPhone(e)}
                                placeholder="(___)___-___" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="state"
                              label="State">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="zip"
                              label="Zip Code">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12 text-right'>
                    <div className="form-group">
                      <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                      <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
                        {loading && (
                          <span className='spinner-border spinner-border-sm mr-1'></span>
                        )}
                        Save
                      </></button>
                    </div>
                  </div>
                </div>
              </div>


            </Form>
          </div>
        </div>
      </div >

    </div>
  )
}

export default CustomerDetail;

export interface ICustomerDetail {
  id: number;
  birthday: number;
  createDate: number;
  name: string;
  email: string;
  customerType: string;
  note: string;
  phone: string;
  registerDate: number;
  shopId: number;
  status: number;
  confirmCode: string;
  balance: number;
  qrCode: string;
  qrCodeImage: string;
}
