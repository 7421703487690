import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const AppParticles = () => {
    const particlesInit = useCallback(async (engine: any) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        await console.log(container);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#fff"
                    }
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push"
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse"
                        },
                        resize: true
                    },
                    modes: {
                        push: {
                            quantity: 1
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.6
                        }
                    }
                },
                particles: {
                    color: {
                        value: "#f49300"
                    },
                    links: {
                        color: "#f49300",
                        distance: 170,
                        enable: true,
                        opacity: 0.6,
                        width: 1
                    },
                    collisions: {
                        enable: true
                    },
                    move: {
                        enable: true,
                        outModes: {
                            default: "bounce"
                        },
                        random: false,
                        speed: 6,
                        straight: false
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800
                        },
                        value: 80
                    },
                    opacity: {
                        value: 0.5
                    },
                    shape: {
                        type: "circle"
                    },
                    size: {
                        value: { min: 1, max: 5 }
                    }
                },
                detectRetina: true
            }}
        />
    );
};

export default AppParticles;
