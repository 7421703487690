import axios from 'axios';
import { AppState, store } from '../store';
import { API_URL } from '../helpers/constants';

const uploadImage = async (file: File) => {
    let currentState = store.getState() as AppState;

    const formData = new FormData();
    formData.append('file', file);
    const token = currentState.account.token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`, // Đặt token ở đây
        },
    };

    return await axios.post(`${API_URL}/images/upload-image`, formData, config).then((response) => {
        return response;
    })
};

const uploadFile = async (file: File) => {
    let currentState = store.getState() as AppState;
    const formData = new FormData();
    formData.append('file', file);
    const token = currentState.account.token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`, // Đặt token ở đây
        },
    };
    return await axios.post(`${API_URL}/file/upload-file`, formData, config).then((response) => {
        return response;
    })
};

export const imageService = {
    uploadImage,
    uploadFile
};