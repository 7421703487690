import message from 'antd/es/message';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { accountService } from '../../services/account.service';
import { Form, Input, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import InputFormatPhone from 'react-phone-number-input/input'

export const AccountEdit = () => {
  const onClose = () => {
    navigate('/account-summary');
  };
  const navigate = useNavigate();

  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [accountDetail, setChiTiet] = useState<any>(null);
  const [isChangePass, setIsChangePass] = useState(false);
  const [inputPhone, setPhone] = useState<any>(null);

  useEffect(() => {
    const fetchCongDoanData = async () => {
      try {
        await accountService.getDetailById(id).then((response) => {
          if (response.code === 200) {
            setChiTiet(response.payload);
          } else if (response.status === 404) {
            message.error('Id not found', onClose);
          } else {
            message.error(response.message, onClose);
          }
        });
      } catch (error: any) {
        message.error(error.message, onClose);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    if (!accountDetail) {
      fetchCongDoanData();
    }
  });

  useEffect(() => {
    if (accountDetail) {
      formEdit.setFieldsValue({
        firstName: accountDetail.firstName,
        lastName: accountDetail.lastName,
        phone: accountDetail.phone,
        email: accountDetail.email,
        address: accountDetail.address,
        state: accountDetail.state,
        city: accountDetail.city,
        zipCode: accountDetail.zipCode,
        isLinkBooking: accountDetail.isLinkBooking,
        timeZone: accountDetail.timeZone,
      });
      setPhone(accountDetail.phone);
    }
  }, [accountDetail, formEdit]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const objectUpdate = {
        accessToken: accountDetail.accessToken,
        accountType: accountDetail.accountType,
        address: values.address,
        balanceAmount: accountDetail.balanceAmount,
        city: values.city,
        confirmCode: accountDetail.confirmCode,
        countryName: accountDetail.countryName,
        createByAccountId: accountDetail.createByAccountId,
        createDate: accountDetail.createDate,
        email: values.email,
        firstName: values.firstName,
        id: accountDetail.id,
        isSendWarningSms1: accountDetail.isSendWarningSms1,
        isSendWarningSms2: accountDetail.isSendWarningSms2,
        lastName: values.lastName,
        numberLoginFail: accountDetail.numberLoginFail,
        phone: inputPhone,
        registerDate: accountDetail.registerDate,
        role: accountDetail.role,
        state: values.state,
        status: accountDetail.status,
        userPresentId: accountDetail.userPresentId,
        zipCode: values.zipCode,
        password: isChangePass ? values.password : '',
        isLinkBooking: values.isBooking ? 1 : 0
      }

      const response = await accountService.updateAccountById(accountDetail.id, objectUpdate);
      if (response.code === 200) {
        message.success('Update successful', onClose)
      } else {
        setLoading(false);
        message.error({ content: response.message, duration: 2 });
      }

    } catch (error: any) {
      setLoading(false);
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div style={{ textAlign: 'right' }} className="align-items-center justify-content-between mb-4">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          <i className="fas fa-fw fa-angle-left"></i>
          Back
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card-header py-3 card-header-customer">
            <div className="group-title"><h4>Edit Company</h4><span>Information Company</span></div>
          </div>
          <div className="card shadow mb-4">
            <Form form={formEdit}
              name="detail" onFinish={onSubmit}
              labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
              <div className="card-body" style={{ marginTop: '60px' }}>
                <div className="row">
                  <div className='col-sm-12'>
                    <h5 className="h5 mb-3" style={{ fontWeight: 'bold' }}>Register Infomation</h5>
                    <div className="row">
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="firstName"
                              label="First name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "The input is not valid E-mail !",
                                }
                              ]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="lastName"
                              label="Last name"
                              rules={[{ required: true }]}>
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[{ required: true }]}>
                              <InputFormatPhone
                                className="form-phone-validate form-control"
                                country="US"
                                autoComplete="off"
                                name="phone"
                                onChange={e => setPhone(e)}
                                placeholder="(___)___-___" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 className="h5 mb-3" style={{ fontWeight: 'bold' }}>Bussiness Address</h5>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="address"
                              label="Address">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="state"
                              label="State">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="city"
                              label="City">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="zipCode"
                              label="Zip Code">
                              <Input size='large' autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 className="h5 mb-3" style={{ fontWeight: 'bold' }}>Booking Appointment</h5>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="isBooking"
                              label="Online Booking Link">
                              <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <Form.Item
                              name="ischangePassword"
                              label="Change Password">
                              <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) => setIsChangePass(checked)} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='cardPassword' style={{ display: isChangePass ? 'block' : 'none' }}>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className="form-group">
                            <div className="col-sm-12">
                              <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!getFieldValue('ischangePassword')) {
                                        return Promise.resolve();
                                      }

                                      if (getFieldValue('ischangePassword') && !value) {
                                        return Promise.reject('Please input your password!');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]} dependencies={['ischangePassword']}
                              >
                                <Input.Password
                                  size="large"
                                  placeholder="New password"
                                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className='col-sm-6'>
                          <div className="form-group">
                            <div className="col-sm-12">
                              <Form.Item
                                name="rePassword"
                                label="Retype password"
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!getFieldValue('ischangePassword')) {
                                        return Promise.resolve();
                                      }

                                      if (getFieldValue('ischangePassword') && !value) {
                                        return Promise.reject('Please input your retype password!');
                                      }
                                      if (value && getFieldValue('password') && value !== getFieldValue('password')) {
                                        return Promise.reject('Passwords do not match!');
                                      }
                                      if (!value && getFieldValue('password')) {
                                        return Promise.reject('Please confirm your password!');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]} dependencies={['password', 'ischangePassword']}>
                                <Input.Password
                                  size="large"
                                  placeholder="Retype new password"
                                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12 text-right'>
                    <div className="form-group">
                      <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                      <button type="submit" className="btn btn-success ml-2" disabled={loading}> <>
                        {loading && (
                          <span className='spinner-border spinner-border-sm mr-1'></span>
                        )}
                        Save
                      </></button>
                    </div>
                  </div>
                </div>
              </div>


            </Form>
          </div>
        </div>
      </div >
    </div >
  )
}

