import React from 'react';
import { Link, useLocation } from 'react-router-dom';
type Props = {
    admin?: boolean
};
export const LeftMenu = ({ admin = false }: Props) => {
    const location = useLocation();

    const isLinkActive = (path: string) => {
        if (path === '/' && location.pathname === '/') {
            // Nếu URL chỉ có domain, không có path
            return true;
        }
        return location.pathname.includes(path);
    };

    return (
        <ul className={'navbar-nav layout-sidebar sidebar sidebar-dark accordion'} id='accordionSidebar'>
            {/* Sidebar - Brand */}
            <div className='sidebar-brand-text mx-3 text-center mb-2'>
                <img style={{ width: '80px', marginTop: '20px' }} src="/images/logo.png" alt="Logo" />
            </div>
            {/* Divider */}
            <div style={{ padding: '15px' }}>
                {admin ?
                    <>
                        <li className='nav-item'>
                            <Link id='tagHome' className={`nav-link ${(isLinkActive('/dashboard')) || (location.pathname === '/') ? 'active' : ''}`}
                                to='/dashboard'>
                                <img className='my-icon' src="/images/icon-home.svg" alt="Icon" />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link className={`nav-link ${(isLinkActive('/account-summary')) || (location.pathname === '/account-summary') ? 'active' : ''}`}
                                to='/account-summary'>
                                <img className='my-icon' src="/images/icon-home.svg" alt="Icon" />
                                <span>Account Summary</span>
                            </Link>
                        </li>
                    </> :
                    <li className='nav-item'>
                        <Link id='tagHome' className={`nav-link ${(isLinkActive('/')) || (location.pathname === '/') ? 'active' : ''}`}
                            to='/account-summary'>
                            <img className='my-icon' src="/images/icon-home.svg" alt="Icon" />
                            <span>Account Summary</span>
                        </Link>
                    </li>
                }
                <li className='nav-item'>
                    <Link
                        className={`nav-link ${isLinkActive('/agent-managment') ? 'active' : ''}`}
                        to='/agent-managment'>
                        <img className='my-icon' src="/images/icon-anti-bot.svg" alt="Icon" />
                        <span>Agent Managment</span>
                    </Link>
                </li>

                <li className='nav-item'>
                    <Link className={`nav-link ${isLinkActive('/sms-managment') ? 'active' : ''}`} to='/sms-managment'>
                        <i className="fas fa-fw fa-sms"></i>
                        <span>SMS Manager</span>
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link className={`nav-link ${isLinkActive('/setting-coupon') ? 'active' : ''}`} to='/setting-coupon'>
                        <span>
                            <img className='my-icon' src="/images/icon-kyc.svg" alt="Icon" />
                            Setting Coupon</span>
                    </Link>
                </li>
            </div>
            <hr className='sidebar-divider' />
        </ul>
    );
};
