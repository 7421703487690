import React, { useEffect, useState, Fragment } from 'react';
import { login as loginAction, logout } from '../../store/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import AppParticles from '../../components/Particles';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input, message } from 'antd';

export const Login = () => {
    const loading = useSelector<AppState>((state) => state.account.loading);
    const error = useSelector<AppState>((state) => state.account.error);
    const [formLogin] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    const onSubmit = async (values: any) => {
        setSubmitted(true);
        try {
            const login = loginAction(values.email, values.password);
            login(dispatch);
        } catch (error: any) {
            setSubmitted(false);
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setSubmitted(false);
        }
    };

    return (
        <Fragment>
            <AppParticles />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <img style={{ width: '150px' }} src="/images/logo.png" alt="Logo" />
                                        <h1 className="h2 text-gray-900 text-black-50 mb-4">Sign In To Admin</h1>
                                    </div>

                                    <Form form={formLogin} name="formLogin" onFinish={onSubmit} className="c-formNew">
                                        <>
                                            {error && (
                                                <div className="alert alert-danger" role="alert">
                                                    {error.toString()}
                                                </div>
                                            )}
                                        </>
                                        <div className="form-group">
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: "email",
                                                        message: "The input is not valid E-mail !",
                                                    }
                                                ]}>
                                                <Input size="large" placeholder='Email' autoComplete="off" />
                                            </Form.Item>
                                        </div>

                                        <div className="form-group">
                                            <Form.Item
                                                name="password"
                                                rules={[{ required: true, message: 'Password is requied !' }]}>
                                                <Input.Password
                                                    size="large"
                                                    placeholder="Password"
                                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group text-center">
                                            <button type="submit" className="btn btn-primary ml-2" disabled={submitted}> <>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                SIGN IN
                                            </></button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>

                    </div>
                </div>
            </div>

        </Fragment>



    )
}
